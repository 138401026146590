/* External dependencies */
import React from 'react';
import { Button, ButtonProps, Modal, Image } from 'react-bootstrap';
import isString from 'lodash/isString';

/* Internal dependencies */
import SubscribeModal from './SubscribeModal';
import Subscribe from 'src/subscribe/Subscribe';
import './SubscribeButton.scss';
import { logEvent } from '@firebase/analytics';
import { analytics } from 'src/firebase';
import QRCode from '../assets/images/beatmatch-app-qr.png';

type OwnProps = ButtonProps & {
  variant?: 'primary' | 'success' | 'secondary';
  children?: React.ReactNode;
  text?: string;
  color?: string;
  backgroundColor?: string;
  header?: React.ReactNode | string;
  render?: (options: { onClick?: Function; href?: string }) => React.ReactNode;
};

type Props = OwnProps;


/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
 const getMobileOperatingSystem = () => {
  var userAgent = window.navigator.userAgent || window.navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return "iOS";
  }

  return "unknown";
};

class SubscribeButton extends React.Component<Props> {
  state = { waitlistOpen: false, qrCodeOpen: false };

  showWaitlistModal = () => {
    this.setState({ waitlistOpen: true });
    logEvent(analytics, 'waitlist_openModal', this.state);
  };

  hideWaitlistModal = () => {
    this.setState({ waitlistOpen: false });
  };

  showQRCodeModal = () => {
    const { header } = this.props;
    this.setState({ qrCodeOpen: true });
    logEvent(analytics, 'download_openModal', { ...this.state, header });
  };

  hideQRCodeModal = () => {
    const { header } = this.props;
    this.setState({ qrCodeOpen: false });
    logEvent(analytics, 'download_closeModal', { ...this.state, header });
  };

  join = async () => {
    // async call
    this.hideWaitlistModal();
    // show success flash
  };

  render() {
    const {
      variant, children, text = 'GET EARLY ACCESS',
      color = '#fff', backgroundColor, className,
      header = 'Get started with Beatmatch', render,
    } = this.props;
    const { waitlistOpen, qrCodeOpen } = this.state;
    const os = getMobileOperatingSystem();
    let onClick: (() => void) | undefined;
    let href: string | undefined;

    if (os === 'iOS' || os === 'Android') {
      onClick = undefined;
      href = 'https://beatmatch.app.link/download';
    } else {
      onClick = this.showQRCodeModal;
      href = undefined;
    }

    return (
      <>
        {Boolean(render) ? render!({ onClick, href }) : (
          <Button
            className={`d-flex align-items-center ${className}`}
            variant={variant}
            onClick={onClick}
            href={href}
            target="_blank"
            style={{
              width: '100%',
              borderRadius: 30,
              fontWeight: 700,
              height: 150,
              color,
              backgroundColor,
            }}
          >
            {children || text}
          </Button>
        )}
        <SubscribeModal
          show={waitlistOpen}
          onShow={() => {
            this.hideQRCodeModal();
            this.showWaitlistModal();
          }}
          onHide={this.hideWaitlistModal}
        />
        <Modal
          animation={true}
          centered={true}
          size="lg"
          show={qrCodeOpen}
          onHide={this.hideQRCodeModal}
        >
          <Modal.Body className="d-flex flex-column justify-content-center align-items-center p-5">
            {isString(header) ? (<h1 className="text-center mb-3">{header}</h1>) : header}
            <h5 className="text-center mb-4">Scan the code to download the app</h5>
            <Image
              className="mb-2"
              src={QRCode}
              width={250}
              height={250}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default SubscribeButton;

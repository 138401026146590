/* External dependencies */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

/* Internal dependencies */
import { addViewForItem, Referrer, ReferrerCategory, ReferrerDomain } from '../api/views';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import store, { ApplicationState } from 'src/store';
import { cleanObject } from 'src/common/cleanObject';
import { getPreviousRoute } from 'src/store/ducks/routes';

type OwnProps = {
  currentUser: CurrentUserState['user'];
  itemId: string;
};

type Props = OwnProps;

const categorizeReferrer = (referrer: string | undefined) => {
  console.log('process.env.NODE_ENV', process.env.NODE_ENV);
  if (!referrer) {
    return ReferrerCategory.direct;
  }

  if (
    referrer.includes(ReferrerDomain.facebook) ||
    referrer.includes(ReferrerDomain.instagram) ||
    referrer.includes(ReferrerDomain.tiktok) ||
    referrer.includes(ReferrerDomain.x) ||
    referrer.includes(ReferrerDomain.twitter)
  ) {
    return ReferrerCategory.social;
  } else if (
    referrer.includes(ReferrerDomain.google) ||
    referrer.includes(ReferrerDomain.bing) ||
    referrer.includes(ReferrerDomain.yahoo) ||
    referrer.includes(ReferrerDomain.duckduckgo)
  ) {
    return ReferrerCategory.search;
  } else if (referrer.includes(ReferrerDomain.beatmatch)) {
    return ReferrerCategory.internal;
  } else if (referrer.includes(ReferrerDomain.localhost)) {
    if (process.env.NODE_ENV === 'development') {
      return ReferrerCategory.internal;
    }

    return ReferrerCategory.local;
  }
};

const ViewTracker: React.FC<Props> = ({ currentUser, itemId }) => {
  const userId = get(currentUser, 'id')!;

  useEffect(() => {
    const trackReferral = async () => {
      if (!userId || !itemId) return;

      const state = store.getState();
      const previousRoute = getPreviousRoute(state);

      // Get the referrer URL
      let referrerUrl = document.referrer;
      let referrerSource = Boolean(referrerUrl) ? new URL(referrerUrl).hostname : undefined;

      if (referrerSource === ReferrerDomain.localhost && process.env.NODE_ENV === 'development') {
        referrerSource = ReferrerDomain.beatmatch;
      } 

      const referrerCategory = categorizeReferrer(referrerUrl);

      if (previousRoute) {
        // If there's a previous location, treat it as internal
        referrerUrl = `https://beatmatch.app${previousRoute}`;
      }

      // Extract UTM parameters from the current URL
      const queryParams = new URLSearchParams(window.location.search);
      const utmSource = queryParams.get('utm_source') || undefined;
      const utmMedium = queryParams.get('utm_medium') || undefined;
      const utmCampaign = queryParams.get('utm_campaign') || undefined;
      const utmContent = queryParams.get('utm_content') || undefined;
      const utmTerm = queryParams.get('utm_term') || undefined;

      return await addViewForItem(userId, itemId, cleanObject({
        referrerSource,
        referrerCategory,
        referrerUrl,
        utmSource,    // Capture UTM parameters
        utmMedium,
        utmCampaign,
        utmContent,
        utmTerm,
      }));
    };

    trackReferral();
  }, [itemId]);

  return null;
};

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps)(ViewTracker);

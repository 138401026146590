/* External dependencies */
import React from 'react';
import Lottie from 'react-lottie';

/* Internal dependencies */
import './Spinner.scss';

const WaveForm = require('../assets/animations/waveform.json');
const WaveFormWhite = require('../assets/animations/waveform-white.json');

type OwnProps = {
  variant?: 'light' | 'dark';
  size?: 'large' | string;
  playing?: boolean;
};

type Props = OwnProps;

const Spinner: React.FunctionComponent<Props> = ({
  variant = 'dark',
  size = 'large',
  playing = true,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: variant === 'light' ? WaveFormWhite : WaveForm,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  let iconSize = size === 'large' ? 100 : 45;

  return (
    <div className="bm-Spinner">
      <Lottie options={defaultOptions} height={iconSize} width={iconSize} isPaused={!playing} speed={0.4} />
    </div>
  );
};

export default Spinner;

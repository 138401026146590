/* Internal dependencies */
import Paginator from './paginator';

export const INITIAL_STATE = {
  items: [],
};

export const PAGINATOR_ID = 'communityFeedItems';
const { reducer, setItems, getItems, removeItem } = new Paginator(PAGINATOR_ID);

/* Selectors */
export const getCommunityFeedItems = getItems;

/* Action Creator */
export const setCommunityFeedItems = setItems;
export const removeCommunityFeedItem = removeItem;

/* Reducers */
export default reducer;

/* External dependencies */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';

/* Internal dependencies */
import './FlatList.scss';
import { cleanObject } from 'src/common/cleanObject';

export type FlatListProps<T> = {
  data: T[];
  renderItem: ({ item, index }: { item: T; index: number; total: number; }) => React.ReactNode;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  horizontal?: boolean;
  contentContainerStyle?: React.CSSProperties;
  grid?: boolean;
  gridGap?: string;
  gridOnMobile?: boolean;
  numColumns?: number;
};

const FlatList: React.FC<FlatListProps<any>> = ({
  data = [], renderItem,
  onEndReached, onEndReachedThreshold = 0.75,
  horizontal = false, contentContainerStyle,
  grid = false, gridGap, gridOnMobile = true,
  numColumns = 3,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleScroll = useCallback(debounce(async () => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = document.documentElement;
    const reachedThreshold = horizontal
      ? scrollLeft + clientWidth >= scrollWidth * onEndReachedThreshold
      : scrollTop + clientHeight >= scrollHeight * onEndReachedThreshold;

    if (reachedThreshold && !isFetching && onEndReached) {
      setIsFetching(true);
      await onEndReached();
    }
  }, 200), [horizontal, isFetching, onEndReached, onEndReachedThreshold]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isFetching) {
      setIsFetching(false);
    }
  }, [data]);

  return (
    <div
      className={`bm-FlatList ${Boolean(grid) ? `bm-FlatList--grid bm-FlatList--grid--${numColumns}-columns ${!Boolean(gridOnMobile) ? 'bm-FlatList--grid--noMobile' : ''}` : ''}`}
      style={cleanObject({
        overflowX: horizontal ? 'auto' : 'hidden',
        overflowY: horizontal ? 'hidden' : 'auto',
        height: horizontal ? 'auto' : '100%',
        width: horizontal ? '100%' : 'auto',
        display: horizontal ? 'flex' : 'block',
        whiteSpace: horizontal ? 'nowrap' : 'normal',
        gap: Boolean(gridGap) ? gridGap : undefined,
        ...contentContainerStyle,
      })}
    >
      {data.map((item, index) => (
        <div key={index} style={{ display: horizontal ? 'inline-block' : 'block' }}>
          {renderItem({ item, index, total: data.length })}
        </div>
      ))}
    </div>
  );
};

export default FlatList;

/* External dependencies */
import { Action, combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';
import { User } from 'src/types/User';

enum UsersActionType {
  SET_USERS = 'reducer/users/SET_USERS',
  UPDATE_USER = 'reducer/users/UPDATE_USER',
  UPDATE_USERS = 'reducer/users/UPDATE_USERS',
}

export type UsersState = {
  items: { [userId: string]: any };
};

export const INITIAL_STATE = {
  items: {},
  offset: null,
  loading: true,
};

/* Selectors */
export const getUsers = (state: ApplicationState) => Object.values(state.users.items);
export const getUser = (state: ApplicationState, userId: string) => state.users.items[userId];

/* Action Creators */
export const setUsers = generateActionCreator<UsersActionType.SET_USERS, UsersState['items']>(
  UsersActionType.SET_USERS
);

export const updateUser = generateActionCreator<UsersActionType.UPDATE_USER, any>(
  UsersActionType.UPDATE_USER
);

export const updateUsers = generateActionCreator<UsersActionType.UPDATE_USERS, any[]>(
  UsersActionType.UPDATE_USERS
);

/* Reducers */
const itemsReducerWrapper = (
  state: UsersState['items'] = INITIAL_STATE.items,
  action: Action & { payload: any }
): UsersState['items'] => {
  const { payload } = action;
  if (action.type === UsersActionType.SET_USERS) {
    return payload.reduce((acc: { [userId: string]: User }, curr: User) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === UsersActionType.UPDATE_USER) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...state[id],
        ...payload,
      },
    };
  } else if (action.type === UsersActionType.UPDATE_USERS) {
    const users = payload;

    return {
      ...state,
      ...users.reduce((acc: { [userId: string]: User }, curr: User) => {
        const { id } = curr;

        return {
          ...acc,
          [id]: {
            ...state[id],
            ...curr,
          },
        };
      }, {}),
    };
  }

  return state;
};

const usersReducer = combineReducers({
  items: itemsReducerWrapper,
});

export default usersReducer;

/* External depencies */
import React, { useMemo } from 'react';
import get from 'lodash/get';

/* Internal depencies */
import User from 'src/types/User';
import { randomItem } from 'src/common/helpers/random';

interface UserAvatarProps {
  user: User;
  className?: string;
  size?: number;
  backgroundColor?: string;
  style?: any;
}

// Function to generate a random pleasant color
const getRandomColor = () => {
  const colors = ['#FFB300', '#6ACCBC', '#F3D24D', '#6BAED6', '#C594C5', '#E55D4A', '#FC814A'];
  return randomItem(colors);
};

// Persistent color map for users
const userColorMap = new Map<string, string>();

const UserDefaultAvatar: React.FC<UserAvatarProps> = ({ user, className, size = 50, backgroundColor, style = {} }) => {
  const userId = get(user, 'id');

  // Use memoized stable color based on user.id
  const stableBackgroundColor = useMemo(() => {
    if (backgroundColor) return backgroundColor; // Use provided color if available

    // Check if a color already exists for this user
    if (!userColorMap.has(userId)) {
      userColorMap.set(userId, getRandomColor());
    }

    // Return the persistent color
    return userColorMap.get(user.id)!;
  }, [userId, backgroundColor]);

  const firstName = get(user, 'firstName', get(user, 'name', ''));
  const lastName = get(user, 'lastName', '');
  const initials = `${get(firstName, '[0]', '')}${get(lastName, '[0]', '')}`.toUpperCase();

  const styles = {
    backgroundColor: stableBackgroundColor,
    color: '#FFFFFF',
    borderRadius: '50%',
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${size / 2.5}px`,
    fontWeight: 'bold',
    userSelect: 'none' as const,
    ...style,
  };

  return (
    <div className={`d-flex justify-content-center align-items-center ${className || ''}`} style={styles}>
      {initials}
    </div>
  );
};

export default UserDefaultAvatar;

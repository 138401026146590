/* Export dependencies */
import React from 'react';
import { withRouter, match, useLocation } from 'react-router';
import Helmet from 'react-helmet';
import get from 'lodash/get';

/* Internal dependencies */
import Navbar from '../navbar/Navbar';
import '../App.scss';
import '../events/Events.scss';
import Footer from 'src/footer/Footer';
import ForFans from './ForFans';
import ForOrganizers from './ForOrganizers';

export enum Persona {
  fans = 'fans',
  creators = 'creators',
}

type InjectedProps = any & {
  match: match<{
    persona: string;
  }>;
  history: any;
};

type OwnProps = {
  spotify?: boolean;
};

type Props = InjectedProps & OwnProps;

const Home: React.FC<Props> = () => {
  const location = useLocation();

  // Determine the path from the location object
  const pathName = get(location, 'pathname');
  let persona = Persona.fans;

  if (pathName === `/${Persona.fans}`) {
    persona = Persona.fans;
  } else if (pathName === `/${Persona.creators}`) {
    persona = Persona.creators;
  }

  const isFansPage = persona === Persona.fans;
  const isCreatorsPage = persona === Persona.creators;
  let metaTitle = 'Beatmatch';

  if (isFansPage) {
    metaTitle = 'Do more together | Beatmatch';
  } else if (isCreatorsPage) {
    metaTitle = 'Amplify your events | Beatmatch for creators';
  }

  return (
    <div className="bg-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTitle}</title>
        <meta property="og:determiner" content="a" />
        <meta
          property="og:description"
          content={`${process.env.REACT_APP_DESCRIPTION}`}
        />
        <meta property="og:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta
          property="og:image"
          content={`http://${process.env.REACT_APP_DOMAIN_NAME}/welcome-to-beatmatch.png`}
        />
        <meta property="og:site_name" content="Beatmatch" />
        <meta
          property="twitter:description"
          content={`${process.env.REACT_APP_DESCRIPTION}`}
        />
        <meta name="twitter:site" content="@joinbeatmatch" />
        <meta name="twitter:creator" content="@joinbeatmatch" />
        <meta property="twitter:url" content={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
        <meta
          property="twitter:image"
          content={`https://${process.env.REACT_APP_DOMAIN_NAME}${process.env.REACT_APP_PUBLIC_URL}/welcome-to-beatmatch.png`}
        />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:card" content="summary" />
        <meta name="facebook-domain-verification" content="ty26p8tc7587fv1uqileyfkler167y" />
        <link rel="canonical" href={`https://${process.env.REACT_APP_DOMAIN_NAME}`} />
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${`https://${process.env.REACT_APP_DOMAIN_NAME}`}",
              "logo": "${`https://${process.env.REACT_APP_DOMAIN_NAME}/logo512.png`}"
            }`}
        </script>
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org",
              "@type": "SoftwareApplication",
              "name": "Beatmatch",
              "operatingSystem": "IOS",
              "applicationCategory": "SocialNetworkingApplication",
              "applicationSubCategory": "MusicApplication",
              "downloadUrl": "https://beatmatch.app.link"
            }`}
        </script>
        <script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/1328aa7a8f414acc4aaad2634/467d56da5fc744cb331e4ee25.js");`}</script>
      </Helmet>
      <Navbar alwaysFillNavbar={false} />
      {Boolean(isFansPage) && <ForFans />}
      {Boolean(isCreatorsPage) && <ForOrganizers />}
      <Footer />
    </div>
  );
}

export default withRouter(Home);
/* External dependencies */
import React, { MouseEventHandler } from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import AuthModal from './AuthModal';
import Button, { Props as ButtonProps } from 'src/button/Button';
import User from 'src/types/User';

type OwnProps = ButtonProps & {
  onClick(user: User): void;
  modalHeader?: string;
  modalSubheader?: string;
  method?: 'facebook' | 'phone';
  variant?: 'primary' | 'success' | 'secondary';
  children?: React.ReactNode;
  text?: string;
  color?: string;
  backgroundColor?: string;
  render?: (func: MouseEventHandler) => React.ReactNode;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type Props = OwnProps & StateProps;

type State = {
  modalOpen: boolean;
};

class AuthModalButton extends React.Component<Props, State> {
  state = { modalOpen: false };

  handleClick: MouseEventHandler = (e) => {
    const { currentUser, onClick } = this.props;

    e && e.preventDefault();
    e && e.stopPropagation();

    if (Boolean(currentUser && currentUser.id)) {
      onClick && onClick(currentUser!);
    } else {
      this.showModal();
    }
  };

  showModal = () => {
    this.setState({ modalOpen: true });
  };

  hideModal = () => {
    this.setState({ modalOpen: false });
  };

  handleSuccess = async (currentUser: User) => {
    const { onClick } = this.props;

    this.hideModal();

    onClick && await onClick(currentUser);
  };

  render() {
    const { modalHeader, modalSubheader, method, children, variant, color, backgroundColor, text, render } = this.props;
    const { modalOpen } = this.state;
    const authModal = Boolean(modalOpen) && (
      <AuthModal
        header={modalHeader}
        subheader={Boolean(modalSubheader) ? modalSubheader : undefined}
        show={modalOpen}
        onSuccess={this.handleSuccess as any}
        onShow={this.showModal}
        onClose={this.hideModal}
        method={method}
      />
    );

    if (render) {
      return (
        <>
          {render(this.handleClick)}
          {authModal}
        </>
      );
    }

    return (
      <>
        <Button
          {...omit(this.props, ['currentUser', 'modalHeader', 'modalSubheader'])}
          variant={variant}
          onClick={this.handleClick}
          style={{
            width: '100%',
            borderRadius: 30,
            fontWeight: 700,
            height: 150,
            color,
            backgroundColor,
          }}
        >
          {children || text}
        </Button>
        {authModal}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

export default connect(mapStateToProps)(AuthModalButton);
/* External dependencies */
import { Action, combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator, generateReducer } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum ActivitiesActionType {
  SET_ACTIVITIES = 'reducer/activities/SET_ACTIVITIES',
  UPDATE_ACTIVITY = 'reducer/activities/UPDATE_ACTIVITY',
  UPDATE_ACTIVITIES = 'reducer/activities/UPDATE_ACTIVITIES',
  REMOVE_ACTIVITY = 'reducer/activities/REMOVE_ACTIVITY',
  SET_OFFSET = 'reducer/activities/SET_OFFSET',
  SET_ACTIVITIES_LOADING = 'reducer/activities/SET_ACTIVITIES_LOADING',

  // Friends
  SET_FRIENDS_ACTIVITIES = 'reducer/friendsActivities/SET_FRIENDS_ACTIVITIES',
  SET_FRIENDS_OFFSET = 'reducer/friendsActivities/SET_FRIENDS_OFFSET',
  SET_FRIENDS_ACTIVITIES_LOADING = 'reducer/friendsActivities/SET_FRIENDS_ACTIVITIES_LOADING',
}

export type ActivitiesState = {
  items: { [activityId: string]: any };
  offset: Object | null;
  loading: boolean;
};

export type FriendsActivitiesState = {
  items: { [activityId: string]: any };
  offset: Object | null;
  loading: boolean;
}

export const INITIAL_STATE = {
  items: {},
  offset: null,
  loading: true,
};

export const FRIENDS_INITIAL_STATE = {
  items: {},
  offset: null,
  loading: true,
};

/* Selectors */
export const getActivities = (state: ApplicationState) => Object.values(state.activities.items);
export const getActivity = (state: ApplicationState, activityId: string) => state.activities.items[activityId];
export const getActivitiesOffset = (state: ApplicationState) => state.activities.offset;
export const getActivitiesLoading = (state: ApplicationState) => state.activities.loading;

export const getFriendsActivities = (state: ApplicationState) => Object.values(state.friendsActivities.items);
export const getFriendsActivity = (state: ApplicationState, activityId: string) => state.friendsActivities.items[activityId];
export const getFriendsActivitiesOffset = (state: ApplicationState) => state.friendsActivities.offset;
export const getFriendsActivitiesLoading = (state: ApplicationState) => state.friendsActivities.loading;

/* Action Creator */
export const setActivities = generateActionCreator<ActivitiesActionType.SET_ACTIVITIES, ActivitiesState['items']>(
  ActivitiesActionType.SET_ACTIVITIES
);

export const updateActivity = generateActionCreator<ActivitiesActionType.UPDATE_ACTIVITY, any>(
  ActivitiesActionType.UPDATE_ACTIVITY
);

export const updateActivities = generateActionCreator<ActivitiesActionType.UPDATE_ACTIVITIES, any[]>(
  ActivitiesActionType.UPDATE_ACTIVITIES
);

export const removeActivity = generateActionCreator<ActivitiesActionType.REMOVE_ACTIVITY, string>(
  ActivitiesActionType.REMOVE_ACTIVITY
);
export const setActivitiesOffset = generateActionCreator<ActivitiesActionType.SET_OFFSET, ActivitiesState['offset']>(
  ActivitiesActionType.SET_OFFSET
);
export const setActivitiesLoading = generateActionCreator<ActivitiesActionType.SET_ACTIVITIES_LOADING, ActivitiesState['loading']>(
  ActivitiesActionType.SET_ACTIVITIES_LOADING
);

// Friends
export const setFriendsActivities = generateActionCreator<ActivitiesActionType.SET_FRIENDS_ACTIVITIES, FriendsActivitiesState['items']>(
  ActivitiesActionType.SET_FRIENDS_ACTIVITIES
);
export const setFriendsActivitiesOffset = generateActionCreator<ActivitiesActionType.SET_FRIENDS_OFFSET, FriendsActivitiesState['offset']>(
  ActivitiesActionType.SET_FRIENDS_OFFSET
);
export const setFriendsActivitiesLoading = generateActionCreator<ActivitiesActionType.SET_FRIENDS_ACTIVITIES_LOADING, FriendsActivitiesState['loading']>(
  ActivitiesActionType.SET_FRIENDS_ACTIVITIES_LOADING
);

/* Reducers */
const itemsReducerWrapper = (
  state: ActivitiesState['items'] = INITIAL_STATE.items,
  action: Action & { payload: any }
): ActivitiesState['items'] => {
  const { payload } = action;
  if (action.type === ActivitiesActionType.SET_ACTIVITIES) {
    return payload.reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === ActivitiesActionType.UPDATE_ACTIVITY) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...state[id],
        ...payload,
      },
    };
  } else if (action.type === ActivitiesActionType.UPDATE_ACTIVITIES) {
    const activities = payload;

    return {
      ...state,
      ...activities.reduce((acc: any, curr: any) => {
        const { id } = curr;

        return {
          ...acc,
          [id]: {
            ...state[id],
            ...curr,
          },
        };
      }, {}),
    };
  } else if (action.type === ActivitiesActionType.REMOVE_ACTIVITY) {
    const newState = { ...state };

    delete newState[payload];
    
    return newState;
  }

  return state;
};

const offsetReducer = generateReducer<ActivitiesActionType.SET_OFFSET, ActivitiesState['offset']>(
  ActivitiesActionType.SET_OFFSET,
  INITIAL_STATE.offset
);

const loadingReducer = generateReducer<ActivitiesActionType.SET_ACTIVITIES_LOADING, ActivitiesState['loading']>(
  ActivitiesActionType.SET_ACTIVITIES_LOADING,
  INITIAL_STATE.loading
);

// Friends
const friendsItemsReducer = generateReducer<ActivitiesActionType.SET_FRIENDS_ACTIVITIES, FriendsActivitiesState['items']>(
  ActivitiesActionType.SET_FRIENDS_ACTIVITIES,
  FRIENDS_INITIAL_STATE.items
);

const friendsOffsetReducer = generateReducer<ActivitiesActionType.SET_FRIENDS_OFFSET, FriendsActivitiesState['offset']>(
  ActivitiesActionType.SET_FRIENDS_OFFSET,
  FRIENDS_INITIAL_STATE.offset
);

const friendsLoadingReducer = generateReducer<ActivitiesActionType.SET_FRIENDS_ACTIVITIES_LOADING, FriendsActivitiesState['loading']>(
  ActivitiesActionType.SET_FRIENDS_ACTIVITIES_LOADING,
  FRIENDS_INITIAL_STATE.loading
);

const activitiesReducer = combineReducers({
  items: itemsReducerWrapper,
  offset: offsetReducer,
  loading: loadingReducer,
});

export const friendsActivitiesReducer = combineReducers({
  items: friendsItemsReducer,
  offset: friendsOffsetReducer,
  loading: friendsLoadingReducer,
});

export default activitiesReducer;

/* Internal dependencies */
import { PaginationOptions } from '../types/Pagination';
import API from '.';
import { cleanObject } from 'src/common/cleanObject';
import { modelUser } from 'src/store/helpers/users';

export const listNotificationsForUser = async (userId: string, { limit, offset }: PaginationOptions) => {
  const response = await API.get({
    path: `/users/${userId}/notifications`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: offset && JSON.stringify(offset),
      }),
    },
  });

  response.items = response.items.map((notification: any) => ({
    ...notification,
    users: {
      ...notification.users,
      items: (notification.users.items || []).map(modelUser),
    },
  }));

  return response;
};
/* External dependencies */
import React from 'react';
import get from 'lodash/get';

/* Internal dependencies */
import MediaObject, { Props as MediaObjectProps } from '../mediaObject/MediaObject';

type OwnProps = {
  artist: any;
  right?: React.ReactNode;
} & Partial<MediaObjectProps>;

type Props = OwnProps;

const Artist: React.FunctionComponent<Props> = ({ artist, right, ...rest }) => {
  return <MediaObject {...rest} text={artist.name} imageType="circle" image={get(artist, 'images[0].url')} right={right} />;
};

export default Artist;

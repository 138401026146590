/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { cleanObject } from 'src/common/cleanObject';
import Order from 'src/types/Order';
import { PaginationOptions } from 'src/types/Pagination';
import API from '.';

type StripeOptions = {
  testMode: boolean;
};

type AddOrderOptions = {
  joinCommunity?: boolean;
};

export const getStripeConfig = async (options: StripeOptions) => {
  const testMode = get(options, 'testMode', false);

  return await API.get({
    path: `/orders/getStripeConfig`,
    options: {
      queryParams: cleanObject({ testMode }),
    },
  });
};

export const createPaymentIntent = async (
  paymentIntent: {
    amount: number;
    currency: 'usd';
    application_fee_amount: number;
    metadata?: Object;
    order: {
      orderItems: {
        items: {
          priceValue: number;
          currency: 'usd';
          quantity: number;
          itemId: string;
        }[];
      };
    };
  },
  options: StripeOptions
) => {
  const testMode = get(options, 'testMode', false);

  return await API.post({
    path: `/orders/createPaymentIntent`,
    options: {
      body: paymentIntent,
      queryParams: cleanObject({ testMode }),
    },
  });
};

export const createConnectedAccount = async (itemId: string, options: StripeOptions) => {
  const testMode = get(options, 'testMode', false);

  return await API.post({
    path: `/orders/createConnectedAccount`,
    options: {
      body: { itemId },
      queryParams: cleanObject({ testMode }),
    },
  });
};

export const createAccountLink = async (
  accountDetails: { itemId: string; stripeConnectedAccountId: string },
  options: StripeOptions
) => {
  const testMode = get(options, 'testMode', false);

  return await API.post({
    path: `/orders/createAccountLink`,
    options: {
      body: accountDetails,
      queryParams: cleanObject({ testMode }),
    },
  });
};

export const getConnectedAccount = async (itemId: string, options: StripeOptions) => {
  const testMode = get(options, 'testMode', false);

  return await API.get({
    path: `/orders/getConnectedAccount/${itemId}`,
    options: {
      queryParams: cleanObject({ testMode }),
    },
  });
};

export const addOrder = async (order: Order, options: AddOrderOptions = { joinCommunity: true }) =>
  await API.post({
    path: `/orders`,
    options: {
      body: order,
      queryParams: cleanObject(options),
    },
  });

export const checkInOrder = async (params: { orderId: string; userId: string; eventId: string; }) => await API.put({
  path: `/orders/${get(params, 'orderId')}/checkInOrder`,
  options: {
    body: params,
  },
});

export const uncheckInOrder = async (params: { orderId: string; userId: string; eventId: string; }) => await API.put({
  path: `/orders/${get(params, 'orderId')}/uncheckInOrder`,
  options: {
    body: params,
  },
});

export const getOrder = async (orderId: string) => {
  return await API.get({
    path: `/orders/${orderId}`,
  });
};

export const listOrders = async (options: PaginationOptions & { startDate?: Date; endDate?: Date }) => {
  const limit = get(options, 'limit');
  const offset = get(options, 'offset');
  const startDate = get(options, 'startDate');
  const endDate = get(options, 'endDate');

  return await API.get({
    path: '/orders',
    options: {
      queryParams: cleanObject({
        limit,
        offset: Boolean(offset) ? JSON.stringify(offset) : undefined,
        startDate: Boolean(startDate) ? startDate!.toISOString() : undefined,
        endDate: Boolean(endDate) ? endDate!.toISOString() : undefined,
      }),
    },
  });
};

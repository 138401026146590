/* Internal dependencies */
import { ParticipantStatus } from '../types/Participant';
import User, { Image } from 'src/types/User';
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions, PaginationResponse } from 'src/types/Pagination';
import { modelUser } from 'src/store/helpers/users';
import Community from 'src/types/Community';
import API from '.';

export const addCommunity = async (community: any) =>
  (await API.post({
    path: `/communities`,
    options: {
      body: community,
    },
  })) as any as Community;

export const updateCommunity = async (community: any) =>
  (await API.put({
    path: `/communities/${community.id}`,
    options: {
      body: community,
    },
  })) as any as Community;

export const addImageForCommunity = async (communityId: string, image: Omit<Image, 'url'>) =>
  await API.post({
    path: `/communities/${communityId}/images`,
    options: {
      body: image,
    },
  });

export const updateImageForCommunity = async (communityId: string, imageIndex: number, image: Omit<Image, 'url'>) =>
  await API.put({
    path: `/communities/${communityId}/images/${imageIndex}`,
    options: {
      body: image,
    },
  });

export const addCreatorForCommunity = async (communityId: string, { id: creatorId, creatorType }: (User | Community) & { creatorType?: string; }) =>
  await API.post({
    path: `/communities/${communityId}/creators`,
    options: {
      body: cleanObject({ creatorId, creatorType }),
    },
  });

export const removeCreatorForCommunity = async (communityId: string, { id: creatorId }: User | Community) =>
  await API.del({
    path: `/communities/${communityId}/creators/${creatorId}`,
  });

export const addMemberForCommunity = async (communityId: string, memberId: string, status: ParticipantStatus) =>
  await API.post({
    path: `/communities/${communityId}/members/${memberId}`,
    options: {
      body: { status },
    },
  });

export const removeMemberForCommunity = async (communityId: string, memberId: string) =>
  await API.del({
    path: `/communities/${communityId}/members/${memberId}`,
  });

export const listMembersForCommunity = async (
  communityId: string,
  options: PaginationOptions & { status?: ParticipantStatus }
) => {
  const response = await API.get({
    path: `/communities/${communityId}/members`,
    options: {
      queryParams: cleanObject(options),
    },
  }) as any;

  return {
    ...response,
    items: response.items && response.items.map(modelUser),
  };
};

export const listEventsForCommunity = async (
  communityId: string,
  { limit, offset }: PaginationOptions
) => {
  return await API.get({
    path: `/communities/${communityId}/events`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: Boolean(offset) ? JSON.stringify(offset) : undefined,
      }),
    },
  });
};

export const listCreatedCommunitiesForUser = async (
  userId: string,
  { limit, offset }: PaginationOptions
): Promise<PaginationResponse<Community>> => {
  return await API.get({
    path: `/users-communities/${userId}/createdCommunities`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: JSON.stringify(offset),
      }),
    },
  });
};

export const listCommunitiesForUser = async (
  userId: string,
  { limit, offset }: PaginationOptions
): Promise<PaginationResponse<Community>> => {
  return await API.get({
    path: `/users-communities/${userId}/communities`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: JSON.stringify(offset),
      }),
    },
  });
};

export const listPastEventsForCommunity = async (
  communityId: string,
  { limit, offset }: PaginationOptions
) => {
  return await API.get({
    path: `/communities/${communityId}/pastEvents`,
    options: {
      queryParams: cleanObject({
        limit,
        offset,
      }),
    },
  });
};

export const getCommunity = async (communityId: string, options?: { cache?: boolean }) =>
  await API.get({
    path: `/communities/${communityId}`,
    options: {
      queryParams: cleanObject({
        cache: options && options.cache,
      }),
    },
  });

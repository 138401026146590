/* External dependencies */
import { combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum CommunitiesActionType {
  SET_COMMUNITIES = 'reducer/communities/SET_COMMUNITIES',
  UPDATE_COMMUNITY = 'reducer/communities/UPDATE_COMMUNITY',
  UPDATE_COMMUNITIES = 'reducer/communities/UPDATE_COMMUNITIES',
}

export type CommunitiesState = {
  items: { [communityId: string]: any };
};

export const INITIAL_STATE = {
  items: {},
};

/* Selectors */
export const getCommunities = (state: ApplicationState) => Object.values(state.communities.items);
export const getCommunity = (state: ApplicationState, communityId: string) => state.communities.items[communityId];

/* Action Creator */
export const setCommunities = generateActionCreator<CommunitiesActionType.SET_COMMUNITIES, CommunitiesState['items']>(
  CommunitiesActionType.SET_COMMUNITIES
);

export const updateCommunity = generateActionCreator<CommunitiesActionType.UPDATE_COMMUNITY, any>(
  CommunitiesActionType.UPDATE_COMMUNITY
);

export const updateCommunities = generateActionCreator<CommunitiesActionType.UPDATE_COMMUNITIES, any[]>(
  CommunitiesActionType.UPDATE_COMMUNITIES
);

/* Reducers */
const itemsReducer = (
  state: CommunitiesState['items'] = INITIAL_STATE['items'],
  action: {
    type: CommunitiesActionType;
    payload: any;
  }
): CommunitiesState['items'] => {
  const { payload } = action;
  if (action.type === CommunitiesActionType.SET_COMMUNITIES) {
    return payload.reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === CommunitiesActionType.UPDATE_COMMUNITY) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...state[id],
        ...payload,
      },
    };
  } else if (action.type === CommunitiesActionType.UPDATE_COMMUNITIES) {
    const communities = payload;

    return {
      ...state,
      ...communities.reduce((acc: any, curr: any) => {
        const { id } = curr;

        return {
          ...acc,
          [id]: {
            ...state[id],
            ...curr,
          },
        };
      }, {}),
    };
  }

  return state;
};

const communitiesReducer = combineReducers({
  items: itemsReducer,
});

export default communitiesReducer;

/* External dependencies */
import { combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator, generateReducer } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum StoriesActionType {
  SET_STORIES = 'reducer/stories/SET_STORIES',
  SET_OFFSET = 'reducer/stories/SET_OFFSET',
  SET_STORIES_LOADING = 'reducer/stories/SET_STORIES_LOADING',
  SET_STORIES_CURRENT_INDEX = 'reducer/stories/SET_STORIES_CURRENT_INDEX',

  // Friends
  SET_FRIENDS_STORIES = 'reducer/friendsStories/SET_FRIENDS_STORIES',
  SET_FRIENDS_STORIES_OFFSET = 'reducer/friendsStories/SET_FRIENDS_STORIES_OFFSET',
  SET_FRIENDS_STORIES_LOADING = 'reducer/friendsStories/SET_FRIENDS_STORIES_LOADING',
  SET_FRIENDS_STORIES_CURRENT_INDEX = 'reducer/friendsStories/SET_FRIENDS_STORIES_CURRENT_INDEX',
}

export type StoriesState = {
  items: any[];
  offset: number;
  loading: boolean;
  currentIndex: number;
};

export type FriendsStoriesState = {
  items: any[];
  offset: number;
  loading: boolean;
  currentIndex: number;
};

export const INITIAL_STATE = {
  items: [],
  offset: 0,
  loading: true,
  currentIndex: 0,
};

export const FRIENDS_INITIAL_STATE = {
  items: [],
  offset: 0,
  loading: true,
  currentIndex: 0,
};

/* Selectors */
export const getStories = (state: ApplicationState) => state.stories.items;
export const getOffset = (state: ApplicationState) => state.stories.offset;
export const getStoriesLoading = (state: ApplicationState) => state.stories.loading;
export const getStoriesCurrentIndex = (state: ApplicationState) => state.stories.currentIndex;

// Friends
export const getFriendsStories = (state: ApplicationState) => state.friendsStories.items;
export const getFriendsOffset = (state: ApplicationState) => state.friendsStories.offset;
export const getFriendsStoriesLoading = (state: ApplicationState) => state.friendsStories.loading;
export const getFriendsStoriesCurrentIndex = (state: ApplicationState) => state.friendsStories.currentIndex;

/* Action Creator */
export const setStories = generateActionCreator<StoriesActionType.SET_STORIES, StoriesState['items']>(
  StoriesActionType.SET_STORIES
);
export const setOffset = generateActionCreator<StoriesActionType.SET_OFFSET, StoriesState['offset']>(
  StoriesActionType.SET_OFFSET
);
export const setStoriesLoading = generateActionCreator<StoriesActionType.SET_STORIES_LOADING, StoriesState['loading']>(
  StoriesActionType.SET_STORIES_LOADING
);
export const setStoriesCurrentIndex = generateActionCreator<StoriesActionType.SET_STORIES_CURRENT_INDEX, StoriesState['currentIndex']>(
  StoriesActionType.SET_STORIES_CURRENT_INDEX
);

// Friends
export const setFriendsStories = generateActionCreator<StoriesActionType.SET_FRIENDS_STORIES, FriendsStoriesState['items']>(
  StoriesActionType.SET_FRIENDS_STORIES
);
export const setFriendsStoriesOffset = generateActionCreator<StoriesActionType.SET_FRIENDS_STORIES_OFFSET, FriendsStoriesState['offset']>(
  StoriesActionType.SET_FRIENDS_STORIES_OFFSET
);
export const setFriendsStoriesLoading = generateActionCreator<StoriesActionType.SET_FRIENDS_STORIES_LOADING, FriendsStoriesState['loading']>(
  StoriesActionType.SET_FRIENDS_STORIES_LOADING
);

/* Reducers */
const itemsReducer = generateReducer<StoriesActionType.SET_STORIES, StoriesState['items']>(
  StoriesActionType.SET_STORIES,
  INITIAL_STATE.items
);
const offsetReducer = generateReducer<StoriesActionType.SET_OFFSET, StoriesState['offset']>(
  StoriesActionType.SET_OFFSET,
  INITIAL_STATE.offset
);
const loadingReducer = generateReducer<StoriesActionType.SET_STORIES_LOADING, StoriesState['loading']>(
  StoriesActionType.SET_STORIES_LOADING,
  INITIAL_STATE.loading
);
const currentIndexReducer = generateReducer<StoriesActionType.SET_STORIES_CURRENT_INDEX, StoriesState['currentIndex']>(
  StoriesActionType.SET_STORIES_CURRENT_INDEX,
  INITIAL_STATE.currentIndex
);

// Friends
const friendsItemsReducer = generateReducer<StoriesActionType.SET_FRIENDS_STORIES, FriendsStoriesState['items']>(
  StoriesActionType.SET_FRIENDS_STORIES,
  INITIAL_STATE.items
);
const friendsOffsetReducer = generateReducer<StoriesActionType.SET_FRIENDS_STORIES_OFFSET, FriendsStoriesState['offset']>(
  StoriesActionType.SET_FRIENDS_STORIES_OFFSET,
  INITIAL_STATE.offset
);
const friendsLoadingReducer = generateReducer<StoriesActionType.SET_FRIENDS_STORIES_LOADING, FriendsStoriesState['loading']>(
  StoriesActionType.SET_FRIENDS_STORIES_LOADING,
  INITIAL_STATE.loading
);

const friendsCurrentIndexReducer = generateReducer<StoriesActionType.SET_FRIENDS_STORIES_CURRENT_INDEX, StoriesState['currentIndex']>(
  StoriesActionType.SET_FRIENDS_STORIES_CURRENT_INDEX,
  INITIAL_STATE.currentIndex
);


const storiesReducer = combineReducers({
  items: itemsReducer,
  offset: offsetReducer,
  loading: loadingReducer,
  currentIndex: currentIndexReducer,
});

export const friendsStoriesReducer = combineReducers({
  items: friendsItemsReducer,
  offset: friendsOffsetReducer,
  loading: friendsLoadingReducer,
  currentIndex: friendsCurrentIndexReducer,
});

export default storiesReducer;

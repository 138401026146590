/* External dependencies */
import React from 'react';

/* Internal dependencies */
import { ReactComponent as BeatmatchOutline } from '../assets/icons/outline/beatmatch.svg';
import { ReactComponent as BeatmatchSolid } from '../assets/icons/solid/beatmatch.svg';
import { ReactComponent as BookmarkOutline} from '../assets/icons/outline/Bookmark.svg';
import { ReactComponent as BookmarkSolid } from '../assets/icons/solid/Bookmark.svg';
import { ReactComponent as BookmarkEyeOutline } from '../assets/icons/outline/Bookmark - Eye.svg';
import { ReactComponent as BookmarkEyeSolid } from '../assets/icons/solid/Bookmark - Eye.svg';
import { ReactComponent as BriefcaseOutline } from '../assets/icons/outline/Briefcase.svg';
import { ReactComponent as BriefcaseSolid } from '../assets/icons/solid/Briefcase.svg';
import { ReactComponent as CakeOutline } from '../assets/icons/outline/Cake.svg';
import { ReactComponent as CakeSolid } from '../assets/icons/solid/Cake.svg';
import { ReactComponent as CalendarDatesOutline } from '../assets/icons/outline/Calendar - Dates.svg';
import { ReactComponent as CalendarDatesSolid } from '../assets/icons/solid/Calendar - Dates.svg';
import { ReactComponent as CalendarPlusOutline } from '../assets/icons/outline/Calendar - Plus.svg';
import { ReactComponent as CalendarPlusSolid } from '../assets/icons/solid/Calendar - Plus.svg';
import { ReactComponent as CashOutline } from '../assets/icons/outline/Cash.svg';
import { ReactComponent as CashSolid } from '../assets/icons/solid/Cash.svg';
import { ReactComponent as CDOutline } from '..//assets/icons/outline/CD.svg';
import { ReactComponent as CDSolid } from '../assets/icons/solid/CD.svg';
import { ReactComponent as CheckOutline } from '../assets/icons/outline/Check.svg';
import { ReactComponent as CheckSolid } from '../assets/icons/solid/Check.svg';
import { ReactComponent as CheckCircleOutline } from '../assets/icons/outline/Check - Circle.svg';
import { ReactComponent as CheckCircleSolid } from '../assets/icons/solid/Check - Circle.svg';
import { ReactComponent as ChevronDownOutline } from '../assets/icons/outline/Chevron - Down.svg';
import { ReactComponent as ChevronDownSolid } from '../assets/icons/solid/Chevron - Down.svg';
import { ReactComponent as ChevronLeftOutline } from '../assets/icons/outline/Chevron - Left.svg';
import { ReactComponent as ChevronLeftSolid } from '../assets/icons/solid/Chevron - Left.svg';
import { ReactComponent as ChevronRightOutline } from '../assets/icons/outline/Chevron - Right.svg';
import { ReactComponent as ChevronRightSolid } from '../assets/icons/solid/Chevron - Right.svg';
import { ReactComponent as CommentOutline } from '../assets/icons/outline/Comment - 2 - Text.svg';
import { ReactComponent as CommentSolid } from '../assets/icons/solid/Comment - 2 - Text.svg';
import { ReactComponent as Comment2Outline } from '../assets/icons/outline/Comment - 2.svg';
import { ReactComponent as Comment2Solid } from '../assets/icons/solid/Comment - 2.svg';
import { ReactComponent as CrossOutline } from '../assets/icons/outline/Cross.svg';
import { ReactComponent as CrossSolid } from '../assets/icons/solid/Cross.svg';
import { ReactComponent as EditOutline } from '../assets/icons/outline/Edit - 3.svg';
import { ReactComponent as EditSolid } from '../assets/icons/solid/Edit - 3.svg';
import { ReactComponent as EmailOutline } from '../assets/icons/outline/Email.svg';
import { ReactComponent as EmailSolid } from '../assets/icons/solid/Email.svg';
import { ReactComponent as ExternalLinkOutline } from '../assets/icons/outline/External Link - 2.svg';
import { ReactComponent as ExternalLinkSolid } from '../assets/icons/solid/External Link - 2.svg';
import { ReactComponent as EyeOutline } from '../assets/icons/outline/Eye.svg';
import { ReactComponent as EyeSolid } from '../assets/icons/solid/Eye.svg';
import { ReactComponent as GearOutline } from '../assets/icons/outline/Gear.svg';
import { ReactComponent as GearSolid } from '../assets/icons/solid/Gear.svg';
import { ReactComponent as GiftOutline } from '../assets/icons/outline/Gift.svg';
import { ReactComponent as GiftSolid } from '../assets/icons/solid/Gift.svg';
import { ReactComponent as GlassCocktailOutline } from '../assets/icons/outline/Glass - Cocktail.svg';
import { ReactComponent as GlassCocktailSolid } from '../assets/icons/solid/Glass - Cocktail.svg';
import { ReactComponent as GraduationOutline } from '../assets/icons/outline/Graduation.svg';
import { ReactComponent as GraduationSolid } from '../assets/icons/solid/Graduation.svg';
import { ReactComponent as HeartOutline } from '../assets/icons/outline/Heart.svg';
import { ReactComponent as HeartSolid } from '../assets/icons/solid/Heart.svg';
import { ReactComponent as ImageOutline } from '../assets/icons/outline/Image.svg';
import { ReactComponent as ImageSolid } from '../assets/icons/solid/Image.svg';
import { ReactComponent as Image3Outline } from '../assets/icons/outline/Image - 3.svg';
import { ReactComponent as Image3Solid } from '../assets/icons/solid/Image - 3.svg';
import { ReactComponent as InfoOutline } from '../assets/icons/outline/Info.svg';
import { ReactComponent as InfoSolid } from '../assets/icons/solid/Info.svg';
import { ReactComponent as InstagramOutline } from '../assets/icons/outline/Instagram.svg';
import { ReactComponent as InstagramSolid } from '../assets/icons/solid/Instagram.svg';
import { ReactComponent as LockOutline } from '../assets/icons/outline/Lock - 2.svg';
import { ReactComponent as LockSolid } from '../assets/icons/solid/Lock - 2.svg';
import { ReactComponent as MicrophoneOutline } from '../assets/icons/outline/Microphone.svg';
import { ReactComponent as MicrophoneSolid } from '../assets/icons/solid/Microphone.svg';
import { ReactComponent as MoreOutline } from '../assets/icons/outline/More - Horiz.svg';
import { ReactComponent as MoreSolid } from '../assets/icons/solid/More - Horiz.svg';
import { ReactComponent as MusicNoteOutline } from '../assets/icons/outline/Music Note - 2.svg';
import { ReactComponent as MusicNoteSolid } from '../assets/icons/solid/Music Note - 2.svg';
import { ReactComponent as PauseOutline } from '../assets/icons/outline/Pause.svg';
import { ReactComponent as PauseSolid } from '../assets/icons/solid/Pause.svg';
import { ReactComponent as PauseCircleOutline } from '../assets/icons/outline/Pause - Circle.svg';
import { ReactComponent as PauseCircleSolid } from '../assets/icons/solid/Pause - Circle.svg';
import { ReactComponent as PhoneOutline } from '../assets/icons/outline/Phone.svg';
import { ReactComponent as PhoneSolid } from '../assets/icons/solid/Phone.svg';
import { ReactComponent as PinOutline } from '../assets/icons/outline/Pin.svg';
import { ReactComponent as PinSolid } from '../assets/icons/solid/Pin.svg';
import { ReactComponent as PlayOutline } from '../assets/icons/outline/Play.svg';
import { ReactComponent as PlaySolid } from '../assets/icons/solid/Play.svg';
import { ReactComponent as PlayCircleOutline } from '../assets/icons/outline/Play - Circle.svg';
import { ReactComponent as PlayCircleSolid } from '../assets/icons/solid/Play - Circle.svg';
import { ReactComponent as PlayNextOutline } from '../assets/icons/outline/Play - Next.svg';
import { ReactComponent as PlayNextSolid } from '../assets/icons/solid/Play - Next.svg';
import { ReactComponent as PlayPreviousOutline } from '../assets/icons/outline/Play - Previous.svg';
import { ReactComponent as PlayPreviousSolid } from '../assets/icons/solid/Play - Previous.svg';
import { ReactComponent as PlusOutline } from '../assets/icons/outline/Plus.svg';
import { ReactComponent as PlusSolid } from '../assets/icons/solid/Plus.svg';
import { ReactComponent as PlusCircleOutline } from '../assets/icons/outline/Plus - Circle.svg';
import { ReactComponent as PlusCircleSolid } from '../assets/icons/solid/Plus - Circle.svg';
import { ReactComponent as QuotesOutline } from '../assets/icons/outline/Quotes.svg';
import { ReactComponent as QuotesSolid } from '../assets/icons/solid/Quotes.svg';
import { ReactComponent as Quotes2Outline } from '../assets/icons/outline/Quotes - 2.svg';
import { ReactComponent as Quotes2Solid } from '../assets/icons/solid/Quotes - 2.svg';
import { ReactComponent as ReceiptOutline } from '../assets/icons/outline/Receipt.svg';
import { ReactComponent as ReceiptSolid } from '../assets/icons/solid/Receipt.svg';
import { ReactComponent as SearchOutline } from '../assets/icons/outline/Search.svg';
import { ReactComponent as SearchSolid } from '../assets/icons/solid/Search.svg';
import { ReactComponent as SendOutline } from '../assets/icons/outline/Send - 2.svg';
import { ReactComponent as SendSolid } from '../assets/icons/solid/Send - 2.svg';
import { ReactComponent as SignOutOutline } from '../assets/icons/outline/Sign Out.svg';
import { ReactComponent as SignOutSolid } from '../assets/icons/solid/Sign Out.svg';
import { ReactComponent as SlidersHorizontalOutline } from '../assets/icons/outline/Sliders - Horiz.svg';
import { ReactComponent as SlidersHorizontalSolid } from '../assets/icons/solid/Sliders - Horiz.svg';
import { ReactComponent as StarOutline } from '../assets/icons/outline/Star.svg';
import { ReactComponent as StarSolid } from '../assets/icons/solid/Star.svg';
import { ReactComponent as TicketOutline } from '../assets/icons/outline/Ticket two.svg';
import { ReactComponent as TicketSolid } from '../assets/icons/solid/Ticket two.svg';
import { ReactComponent as Ticket2Outline } from '../assets/icons/outline/Ticket.svg';
import { ReactComponent as Ticket2Solid } from '../assets/icons/solid/Ticket.svg';
import { ReactComponent as TikTokOutline } from '../assets/icons/outline/TikTok.svg';
import { ReactComponent as TikTokSolid } from '../assets/icons/solid/TikTok.svg';
import { ReactComponent as TimeOutline } from '../assets/icons/outline/Time.svg';
import { ReactComponent as TimeSolid } from '../assets/icons/solid/Time.svg';
import { ReactComponent as TrashOutline } from '../assets/icons/outline/Trash.svg';
import { ReactComponent as TrashSolid } from '../assets/icons/solid/Trash.svg';
import { ReactComponent as UserOutline } from '../assets/icons/outline/User.svg';
import { ReactComponent as UserSolid } from '../assets/icons/solid/User.svg';
import { ReactComponent as UsersOutline } from '../assets/icons/outline/Users.svg';
import { ReactComponent as UsersSolid } from '../assets/icons/solid/Users.svg';
import { ReactComponent as VerifiedOutline } from '../assets/icons/outline/Verified.svg';
import { ReactComponent as VerifiedSolid } from '../assets/icons/solid/Verified.svg';
import { ReactComponent as VolumeHighOutline } from '../assets/icons/outline/Volume - High.svg';
import { ReactComponent as VolumeHighSolid } from '../assets/icons/solid/Volume - High.svg';
import { ReactComponent as VolumeCrossOutline } from '../assets/icons/outline/Volume - Cross.svg';
import { ReactComponent as VolumeCrossSolid } from '../assets/icons/solid/Volume - Cross.svg';
import './Icon.scss';

type Props = {
  name: Icons;
  solid?: boolean;
  size: number | string;
  className?: string;
  style?: Object;
};

export enum Icons {
  beatmatch = 'beatmatch.svg',
  bookmark = 'Bookmark.svg',
  bookmarkEye = 'Bookmark - Eye.svg',
  briefcase = 'Briefcase.svg',
  cake = 'Cake.svg',
  calendarDates = 'Calendar - Dates.svg',
  calendarPlus = 'Calendar - Plus.svg',
  cash = 'Cash.svg',
  cd = 'CD.svg',
  check = 'Check.svg',
  checkCircle = 'Check - Circle.svg',
  chevronDown = 'Chevron - Down.svg',
  chevronLeft = 'Chevron - Left.svg',
  chevronRight = 'Chevron - Right.svg',
  comment = 'Comment - 2 - Text.svg',
  comment2 = 'Comment - 2.svg',
  cross = 'Cross.svg',
  edit = 'Edit - 3.svg',
  email = 'Email.svg',
  externalLink = 'External Link - 2.svg',
  eye = 'Eye.svg',
  gear = 'Gear.svg',
  gift = 'Gift.svg',
  glassCocktail = 'Glass - Cockatail.svg',
  graduation = 'Graduation.svg',
  heart = 'Heart.svg',
  image = 'Image.svg',
  image3 = 'Image - 3.svg',
  info = 'Info.svg',
  instagram = 'Instagram.svg',
  lock = 'Lock - 2.svg',
  microphone = 'Microphone.svg',
  more = 'More - Horiz.svg',
  musicNote = 'Music Note - 2.svg',
  pause = 'Pause.svg',
  pauseCircle = 'Pause - Circle.svg',
  phone = 'Phone.svg',
  pin = 'Pin.svg',
  play = 'Play.svg',
  playCircle = 'Play - Circle.svg',
  playNext = 'Play - Next.svg',
  playPrevious = 'Play - Previous.svg',
  plus = 'Plus.svg',
  plusCircle = 'Plus - Circle.svg',
  quotes = 'Quotes.svg',
  quotes2 = 'Quotes - 2.svg',
  receipt = 'Receipt.svg',
  search = 'Search.svg',
  send = 'Send - 2.svg',
  signOut = 'Sign Out.svg',
  star = 'Star.svg',
  slidersHorizontal = 'Sliders - Horiz.svg',
  ticket = 'Ticket two.svg',
  ticket2 = 'Ticket.svg',
  tikTok = 'TikTok.svg',
  time = 'Time.svg',
  trash = 'Trash.svg',
  user = 'User.svg',
  users = 'Users.svg',
  verified = 'Verified.svg',
  volumeHigh = 'Volume - High.svg',
  volumeCross = 'Volume - Cross.svg',
}

const OUTLINE_ICONS: { [iconName: string]: any } = {
  [Icons.beatmatch]: BeatmatchOutline,
  [Icons.bookmark]: BookmarkOutline,
  [Icons.bookmarkEye]: BookmarkEyeOutline,
  [Icons.briefcase]: BriefcaseOutline,
  [Icons.cake]: CakeOutline,
  [Icons.calendarDates]: CalendarDatesOutline,
  [Icons.calendarPlus]: CalendarPlusOutline,
  [Icons.cash]: CashOutline,
  [Icons.cd]: CDOutline,
  [Icons.check]: CheckOutline,
  [Icons.checkCircle]: CheckCircleOutline,
  [Icons.chevronDown]: ChevronDownOutline,
  [Icons.chevronLeft]: ChevronLeftOutline,
  [Icons.chevronRight]: ChevronRightOutline,
  [Icons.comment]: CommentOutline,
  [Icons.comment2]: Comment2Outline,
  [Icons.cross]: CrossOutline,
  [Icons.edit]: EditOutline,
  [Icons.email]: EmailOutline,
  [Icons.externalLink]: ExternalLinkOutline,
  [Icons.eye]: EyeOutline,
  [Icons.gear]: GearOutline,
  [Icons.gift]: GiftOutline,
  [Icons.glassCocktail]: GlassCocktailOutline,
  [Icons.graduation]: GraduationOutline,
  [Icons.heart]: HeartOutline,
  [Icons.image]: ImageOutline,
  [Icons.image3]: Image3Outline,
  [Icons.info]: InfoOutline,
  [Icons.instagram]: InstagramOutline,
  [Icons.lock]: LockOutline,
  [Icons.microphone]: MicrophoneOutline,
  [Icons.more]: MoreOutline,
  [Icons.musicNote]: MusicNoteOutline,
  [Icons.pause]: PauseOutline,
  [Icons.pauseCircle]: PauseCircleOutline,
  [Icons.phone]: PhoneOutline,
  [Icons.pin]: PinOutline,
  [Icons.play]: PlayOutline,
  [Icons.playCircle]: PlayCircleOutline,
  [Icons.playNext]: PlayNextOutline,
  [Icons.playPrevious]: PlayPreviousOutline,
  [Icons.plus]: PlusOutline,
  [Icons.plusCircle]: PlusCircleOutline,
  [Icons.quotes]: QuotesOutline,
  [Icons.quotes2]: Quotes2Outline,
  [Icons.receipt]: ReceiptOutline,
  [Icons.search]: SearchOutline,
  [Icons.send]: SendOutline,
  [Icons.signOut]: SignOutOutline,
  [Icons.slidersHorizontal]: SlidersHorizontalOutline,
  [Icons.star]: StarOutline,
  [Icons.ticket]: TicketOutline,
  [Icons.ticket2]: Ticket2Outline,
  [Icons.tikTok]: TikTokOutline,
  [Icons.time]: TimeOutline,
  [Icons.trash]: TrashOutline,
  [Icons.user]: UserOutline,
  [Icons.users]: UsersOutline,
  [Icons.verified]: VerifiedOutline,
  [Icons.volumeHigh]: VolumeHighOutline,
  [Icons.volumeCross]: VolumeCrossOutline,
};

const SOLID_ICONS: { [iconName: string]: any } = {
  [Icons.beatmatch]: BeatmatchSolid,
  [Icons.bookmark]: BookmarkSolid,
  [Icons.bookmarkEye]: BookmarkEyeSolid,
  [Icons.briefcase]: BriefcaseSolid,
  [Icons.cake]: CakeSolid,
  [Icons.calendarDates]: CalendarDatesSolid,
  [Icons.calendarPlus]: CalendarPlusSolid,
  [Icons.cash]: CashSolid,
  [Icons.cd]: CDSolid,
  [Icons.check]: CheckSolid,
  [Icons.checkCircle]: CheckCircleSolid,
  [Icons.chevronDown]: ChevronDownSolid,
  [Icons.chevronLeft]: ChevronLeftSolid,
  [Icons.chevronRight]: ChevronRightSolid,
  [Icons.comment]: CommentSolid,
  [Icons.comment2]: Comment2Solid,
  [Icons.cross]: CrossSolid,
  [Icons.edit]: EditSolid,
  [Icons.email]: EmailSolid,
  [Icons.externalLink]: ExternalLinkSolid,
  [Icons.eye]: EyeSolid,
  [Icons.gear]: GearSolid,
  [Icons.gift]: GiftSolid,
  [Icons.glassCocktail]: GlassCocktailSolid,
  [Icons.graduation]: GraduationSolid,
  [Icons.heart]: HeartSolid,
  [Icons.image]: ImageSolid,
  [Icons.image3]: Image3Solid,
  [Icons.info]: InfoSolid,
  [Icons.instagram]: InstagramSolid,
  [Icons.lock]: LockSolid,
  [Icons.microphone]: MicrophoneSolid,
  [Icons.more]: MoreSolid,
  [Icons.musicNote]: MusicNoteSolid,
  [Icons.pause]: PauseSolid,
  [Icons.pauseCircle]: PauseCircleSolid,
  [Icons.phone]: PhoneSolid,
  [Icons.pin]: PinSolid,
  [Icons.play]: PlaySolid,
  [Icons.playCircle]: PlayCircleSolid,
  [Icons.playNext]: PlayNextSolid,
  [Icons.playPrevious]: PlayPreviousSolid,
  [Icons.plus]: PlusSolid,
  [Icons.plusCircle]: PlusCircleSolid,
  [Icons.quotes]: QuotesSolid,
  [Icons.quotes2]: Quotes2Solid,
  [Icons.receipt]: ReceiptSolid,
  [Icons.search]: SearchSolid,
  [Icons.send]: SendSolid,
  [Icons.signOut]: SignOutSolid,
  [Icons.slidersHorizontal]: SlidersHorizontalSolid,
  [Icons.star]: StarSolid,
  [Icons.ticket]: TicketSolid,
  [Icons.ticket2]: Ticket2Solid,
  [Icons.tikTok]: TikTokSolid,
  [Icons.time]: TimeSolid,
  [Icons.trash]: TrashSolid,
  [Icons.user]: UserSolid,
  [Icons.users]: UsersSolid,
  [Icons.verified]: VerifiedSolid,
  [Icons.volumeHigh]: VolumeHighSolid,
  [Icons.volumeCross]: VolumeCrossSolid,
};

const Icon: React.FC<Props> = ({ name, solid = false, size, className, style }) => {
  let IconComponent;
  let iconSet = OUTLINE_ICONS;

  if (solid) {
    iconSet = SOLID_ICONS;
  }

  IconComponent = iconSet[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <div className={`bm-Icon ${className}`} style={style}>
      <IconComponent width={size} height={size} />
    </div>
  );
};

export default Icon;
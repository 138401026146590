/* Internal dependencies */
import API from '.';
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions } from 'src/types/Pagination';


export const listAchievementsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get({
    path: `/users/${userId}/achievements`,
    options: {
      queryParams: cleanObject({
        limit,
        offset,
      }),
    }
  });

export const listAchievementGroupsForUser = async (userId: string, { limit, offset }: PaginationOptions) =>
  await API.get({
    path: `/users-achievementGroups/${userId}/achievementGroups`,
    options: {
      queryParams: cleanObject({
        limit,
        offset,
      }),
    },
  });
/* External dependencies */
import React from 'react';
import { Image, ImageProps } from 'react-bootstrap';

/* Internal dependencies */
import './FeatureSection.scss';

type OwnProps = {
  heading: string;
  headingSize?: 'md' | 'lg';
  subheading?: string;
  image: ImageProps['src'];
  actionButton?: React.ReactNode;
  reverse?: boolean;
};

type Props = OwnProps;

const FeatureSection: React.FC<Props> = ({ heading, headingSize = 'md', subheading, image, actionButton, reverse = false }) => {
  return (
    <div
      className={`bm-FeatureSection text-white container d-flex justify-content-between align-items-center flex-wrap ${Boolean(reverse) ? 'flex-row-reverse' : ''}`}
      style={{ width: '100%' }}
    >
      <div className="bm-FeatureSection__bannerImage">
        <Image src={image} width="100%" rounded={true} />
      </div>
      <div className="bm-FeatureSection__bannerDescription text-left">
        {Boolean(heading) && <h1 className={`bm-FeatureSection_heading bm-FeatureSection_heading--${headingSize} mb-4 text-bold`}>{heading}</h1>}
        {Boolean(subheading) && <h3 className="bm-FeatureSection_subheading mb-4">{subheading}</h3>}
        {Boolean(actionButton) && actionButton}
      </div>
    </div>
  );
};

export default FeatureSection;
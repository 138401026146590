/* External dependencies */
import { combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum PostsActionType {
  SET_POSTS = 'reducer/posts/SET_POSTS',
  UPDATE_POST = 'reducer/posts/UPDATE_POST',
}

export type PostsState = {
  items: { [postId: string]: any };
};

export const INITIAL_STATE = {
  items: {},
};

/* Selectors */
export const getPosts = (state: ApplicationState) => Object.values(state.posts.items);
export const getPost = (state: ApplicationState, postId: string) => state.posts.items[postId];

/* Action Creator */
export const setPosts = generateActionCreator<PostsActionType.SET_POSTS, PostsState['items']>(
  PostsActionType.SET_POSTS
);

export const updatePost = generateActionCreator<PostsActionType.UPDATE_POST, any>(
  PostsActionType.UPDATE_POST
);

/* Reducers */

const itemsReducer = (
  state: PostsState['items'] = INITIAL_STATE['items'],
  action: {
    type: PostsActionType;
    payload: any;
  }
): PostsState['items'] => {
  const { payload = [] } = action;
  if (action.type === PostsActionType.SET_POSTS) {
    return payload.reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === PostsActionType.UPDATE_POST) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...state[id],
        ...payload,
      },
    };
  }
  return state;
};

const postsReducer = combineReducers({
  items: itemsReducer,
});

export default postsReducer;

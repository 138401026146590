import { isPlainObject } from 'lodash';

type PlainObject = { [key: string]: any };

/**
 * Utility function to remove all top-level undefined values from an object.
 * Useful for stripping unnecessary values prior to fetching an API.
 * @param object The object to be stripped of its undefined values
 */
export const cleanObject = <T>(object: PlainObject): T => {
  return Object.keys(object).reduce((newObject: PlainObject, key: string) => {
    const value = object[key];
    if (value !== undefined) {
      newObject[key] = value;
    }
    return newObject;
  }, {}) as T;
};

/**
 * Utility function to remove all undefined values from an object including the nested values.
 * Useful for stripping unnecessary values prior to fetching an API.
 * @param object The object to be stripped of its undefined values
 */
export const cleanObjectDeep = <T>(object: PlainObject): T => {
  return Object.keys(object).reduce((newObject: PlainObject, key: string) => {
    let value = object[key];

    if (isPlainObject(value)) {
      value = cleanObjectDeep(value);
    }

    if (value !== undefined) {
      newObject[key] = value;
    }
    return newObject;
  }, {}) as T;
};

/* External dependencies */
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Form, Modal, Spinner } from 'react-bootstrap';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

/* Internal dependencies */
import { PaginationOptions, PaginationResponse } from 'src/types/Pagination';
import FlatList, { FlatListProps } from 'src/flatList/FlatList';
import { fetchAll } from 'src/common/helpers/fetchAll';
import Analytics from 'src/analytics/Analytics';
import Colors from 'src/colors';
import Icon, { Icons } from 'src/icon/Icon';
import './SearchModal.scss';

type OwnProps = {
  header?: string;
  subheader?: string;
  open?: boolean;
  items: any[];
  setItems(items: any[]): void;
  onInitialLoad?: () => Promise<PaginationResponse>;
  onQueryUpdate(query: string, options: PaginationOptions): Promise<PaginationResponse>;
  onRefresh?: (query: string) => void;
  onLoadNextPage?: (query: string, options: PaginationOptions) => Promise<PaginationResponse>;
  pageSize?: number;
  empty?: React.ReactNode;
  emptyAndNotFocused?: React.ReactNode;
  noResults?: React.ReactNode;
  renderItem: FlatListProps<any>['renderItem'];
  onOpen(): void;
  onClose?: () => void;
  onItemClick?: (item: any) => void;
  placeholder?: string;
  itemType?: string;
};

export type Props = OwnProps;

type State = {
  defaultItems: any[];
  query: string;
  loading: boolean;
  offset?: PaginationOptions['offset'];
};

const SearchModal: React.FC<Props> = ({
  items = [],
  setItems,
  onInitialLoad,
  onQueryUpdate,
  onLoadNextPage,
  pageSize = 15,
  empty = (
    <div className="d-flex justify-content-center p-4" style={{ flex: 1 }}>
      <h5 className="bm-Text--secondaryText">No results. Try another search.</h5>
    </div>
  ),
  open,
  onOpen,
  onClose,
  onItemClick,
  renderItem,
  placeholder = 'Search',
  itemType = 'event',
}) => {
  const [defaultItems, setDefaultItems] = useState<State['defaultItems']>([]);
  const [query, setQuery] = useState<State['query']>('');
  const [offset, setOffset] = useState<State['offset']>(undefined);
  const [loading, setLoading] = useState<State['loading']>(onInitialLoad ? true : false);
  const lastQuery = useRef<string | undefined>('');
  const textInput = useRef<any | null>(null);

  useEffect(() => {
    const load = async () => {
      if (onInitialLoad) {
        try {
          const { items: defaultItems = [] } = await onInitialLoad();
          setDefaultItems(defaultItems);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
    }

    load();
  }, [onInitialLoad]);

  const reset = () => {
    setQuery('');
    setItems([]);
    setOffset(undefined);
  };

  const handleOpen = () => {
    onOpen && onOpen();
  };

  const handleClose = () => {
    reset();
    onClose && onClose();
  };

  const handleQueryUpdate = useCallback(async (q: string) => {
    try {
      setLoading(true);
      lastQuery.current = q;

      if (!q) {
        reset();
        setLoading(false);
        return;
      }

      setQuery(q);
      setItems([]);
      
      await handleQueryAsync(q);
    } catch (e) {
      console.log('handleQueryUpdate error: ', e);
    }
  }, [lastQuery]);

  const handleQueryAsync = debounce(async (q: string) => {
    try {
      const [{ items = [], offset }] = await fetchAll([
        onQueryUpdate(q, { limit: pageSize }),
        Analytics.trackSearch({ searchQuery: q, searchType: itemType }),
      ]);

      if (q && lastQuery.current === q) {
        setItems([...uniqBy(items, 'id')]);
        setOffset(offset);
      }
    } catch (e) {
      console.log('handleQueryAsync error: ', e);
    } finally {
      setLoading(false);
    }
  }, 25, { maxWait: 125 });

  const loadNextPage = async () => {
    if (!onLoadNextPage) return;

    const { items: newItems = [], offset: newOffset } = await onLoadNextPage(query, { offset, limit: pageSize });
    const finalItems = [...items, ...newItems];
    setItems(finalItems);
    setOffset(newOffset);
  };

  const renderSkeleton = () => (
    <div className="d-flex justify-content-center" style={{ width: '100%' }}>
      <Spinner variant="grow" size="sm" />
    </div>
  );

  return (
    <Modal
      size="lg"
      className="bm-SearchModal bm-Modal--mobile text-white"
      contentClassName="bg-secondaryButton"
      scrollable={true}
      centered={true}
      show={open}
      onShow={handleOpen}
      onHide={handleClose}
    >
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
          <div className="d-flex align-items-center" style={{ flex: 1 }}>
            <Icon name={Icons.search} className="bm-Icon--white" size={28} />
            <Form.Control
              ref={textInput}
              type="text"
              className="bm-Search__input bm-Search__formControl shadow-none"
              placeholder={placeholder}
              autoFocus={true}
              value={query}
              onInput={(e: FormEvent) => {
                const query = get(e, 'target.value', '');

                handleQueryUpdate(query);
              }}
              autoCapitalize="none"
              spellCheck={false}
              style={{
                color: Colors.primaryText,
                flex: 1,
                fontFamily: 'gotham-bold',
                height: '100%',
                fontSize: 16,
              }}
            />
          </div>
          <button onClick={handleClose} className="d-md-none btn pr-0">
            <span className="material-icons text-white">close</span>
          </button>
        </div>
      </Modal.Header>
      {Boolean(query || loading || items.length || defaultItems.length) && (
        <Modal.Body>
          <div>
            {loading && renderSkeleton()}
            {Boolean(!loading && query && items.length) && (
              <div style={{ width: '100%' }}>
                <FlatList
                  // refreshing={loading}
                  // onRefresh={() => {
                  //   handleQueryUpdate(query);
                  // }}
                  onEndReached={loadNextPage}
                  onEndReachedThreshold={0.6}
                  data={items}
                  renderItem={(params) => {
                    const item = get(params, 'item');
                    const content = renderItem(params);

                    if (!onItemClick) {
                      return content;
                    }

                    return (
                      <button
                        type="button"
                        className="bm-Search__searchItem btn m-0 p-0"
                        onClick={(e) => {
                          if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                          }

                          onItemClick(item);
                          handleClose();
                        }}
                        style={{ maxHeight: 'none', maxWidth: 'none', width: '100%' }}
                      >
                        {content}
                      </button>
                    );
                  }}
                />
              </div>
            )}
            {!loading && !Boolean(query) && Boolean(defaultItems.length) && (
              <FlatList
                onEndReached={loadNextPage}
                data={defaultItems}
                renderItem={renderItem}
              />
            )}
            {Boolean(query) && !loading && !Boolean(defaultItems.length) && !Boolean(items.length) && Boolean(empty) && empty}
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default SearchModal;
/* External dependencies */
import React from 'react';
import get from 'lodash/get';
import moment from 'moment-timezone';

/* Internal dependencies */
import MediaObject, { Props as MediaObjectProps } from '../mediaObject/MediaObject';
import './Event.scss';
import { Badge } from 'react-bootstrap';
import { ParticipantStatus } from 'src/types/Participant';
import Colors from 'src/colors';
import { Link } from 'react-router-dom';
import { providerToProviderCode } from 'src/events/Events';

type OwnProps = {
  event: any;
  right?: React.ReactNode;
  showHostBadge?: boolean;
  enableLink?: boolean;
} & Partial<MediaObjectProps>;

type Props = OwnProps;

const Event: React.FunctionComponent<Props> = ({ event, right, enableLink = false, textSize, subtextSize = '0.85rem', showHostBadge = true, ...rest }) => {
  const name = get(event, 'name');
  const startDate = get(event, 'startDate', get(event, 'date', new Date()));
  const venue = get(event, 'venue');
  const momentDate = moment(startDate);
  const timezone = get(event, 'timezone', 'America/Los_Angeles');
  const today = moment();
  const tomorrow = moment().add(1, 'day');
  const isToday = momentDate.isSame(today, 'day');
  const isTomorrow = momentDate.isSame(tomorrow, 'day');
  const creatorType = get(event, 'creatorType');
  const creatorStatus = get(event, 'creatorStatus');
  const provider = get(event, 'provider');
  const providerId = get(event, 'providerId');

  const content = (
    <MediaObject
      {...rest}
      text={(
        <>
          <div className="d-flex align-items-center m-0 p-0 mb-1">
            {Boolean(enableLink) ? (
              <Link
                to={`/events/${providerToProviderCode[provider]}/${providerId}`}
              >
                <h6 className="bm-MediaObject_text d-flex flex-wrap text-bold text-white m-0 p-0" style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: textSize }}>{name}</h6>
              </Link>
            ) : (
              <h6 className="bm-MediaObject_text d-flex flex-wrap text-bold text-white m-0 p-0" style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: textSize }}>{name}</h6>
            )}
            {Boolean(showHostBadge && creatorType && creatorStatus) && (
              <div className="ml-2">
                {Boolean(creatorStatus === ParticipantStatus.confirmed) && (
                  <Badge
                    pill
                    bg={Boolean(creatorType === 'owner') ? 'primary' : 'info'}
                    className="pt-1 pb-1 pl-2 pr-2"
                  >
                  {Boolean(creatorType === 'owner') ? 'Host' : 'Co-Host'}
                  </Badge>
                )}
                {Boolean(creatorStatus === ParticipantStatus.invited) && (
                  <Badge pill bg="secondary" className="pt-1 pb-1 pl-2 pr-2">
                    Invited
                  </Badge>
                )}
                {Boolean(creatorStatus === ParticipantStatus.denied) && (
                  <Badge pill bg="warning" className="pt-1 pb-1 pl-2 pr-2">
                    Declined invitation
                  </Badge>
                )}
              </div>
            )}
          </div>
          <div className="d-flex align-items-center m-0 p-0 mb-1">
            {(isToday || isTomorrow) && (
              <>
                <h6 className="text-bold text-primary mr-2 m-0 p-0" style={{ marginBottom: 0, fontSize: subtextSize }}>
                  {isToday ? 'TODAY' : isTomorrow ? 'TOMORROW' : momentDate.tz(timezone).format('ddd').toUpperCase()}
                </h6>
                <h6 className="text-bold text-white m-0 p-0" style={{ marginBottom: 0, fontSize: subtextSize }}>
                {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()}
                </h6>
              </>
            )}
            {!isToday && !isTomorrow && (
              <>
                <h6 className="text-bold text-primary mr-2 m-0 p-0" style={{ marginBottom: 0, fontSize: subtextSize }}>
                  {momentDate.tz(timezone).format('ddd').toUpperCase()}
                </h6>
                <h6 className="text-bold text-white m-0 p-0" style={{ marginBottom: 0, fontSize: subtextSize }}>
                  {momentDate.tz(timezone).format('MMM').toUpperCase()} {momentDate.tz(timezone).format('D').toUpperCase()} -{' '}
                  {momentDate.tz(timezone).format('h:mm A')}
                </h6> 
              </>
            )}
          </div>
          {Boolean(venue) && (
            <h6
              className="bm-MediaObject_text MediaObject__subtext d-flex flex-wrap bm-Event__horizontalItemSubtext m-0 p-0"
              style={{ maxWidth: '100%', overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis', fontSize: subtextSize }}
            >
              {(venue as any).name}
            </h6>
          )}
        </>
      )}
      imageType="rounded"
      image={get(event, 'images[0].url')}
      right={right}
    />
  );

  if (enableLink) {
    return (
      <Link
        to={`/events/${providerToProviderCode[provider]}/${providerId}`}
      >
        {content}
      </Link>
    );
  }

  return content;
};

export default Event;

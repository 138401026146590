/* External dependencies */
import { Action } from 'redux';
import get from 'lodash/get';
import { LOCATION_CHANGE } from 'connected-react-router';

/* Internal dependencies */
import { ApplicationState } from '..';

export type RoutesState = {
  currentRoute: string | null;  // The current route is a string path or null if not yet set
  previousRoute: string | null; // The previous route is a string path or null if not yet set
};

export const INITIAL_STATE: RoutesState = {
  currentRoute: null,
  previousRoute: null,
};

/* Selectors */
export const getCurrentRoute = (state: ApplicationState) => get(state, 'routes.currentRoute');
export const getPreviousRoute = (state: ApplicationState) => get(state, 'routes.previousRoute');

const routesReducer = (state: RoutesState = INITIAL_STATE, action: Action) => {
  if (action.type === LOCATION_CHANGE) {
    const nextRoute = get(action, 'payload.location.pathname');
    const currentRoute = get(state, 'currentRoute');
      
    // Only update if the route has changed
    if (currentRoute !== nextRoute) {
      return {
        previousRoute: currentRoute,
        currentRoute: nextRoute,
      };
    }
  }
  return state;
};

export default routesReducer;

import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');
if (root && root.hasChildNodes()) {
  hydrateRoot(root, <App />);
} else {
  createRoot(root!).render(<App />);
}

// Register this with google analytics at some point: https://create-react-app.dev/docs/measuring-performance/
// reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

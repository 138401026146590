/* External dependencies */
import { combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum CommentsActionType {
  SET_COMMENTS = 'reducer/comments/SET_COMMENTS',
  UPDATE_COMMENTS = 'reducer/comments/UPDATE_COMMENTS',
  UPDATE_COMMENT = 'reducer/comments/UPDATE_COMMENT',
}

export type CommentsState = {
  items: { [commentId: string]: any };
};

export const INITIAL_STATE = {
  items: {},
};

/* Selectors */
export const getComments = (state: ApplicationState) => Object.values(state.comments.items);
export const getComment = (state: ApplicationState, commentId: string) => state.comments.items[commentId];

/* Action Creator */
export const setComments = generateActionCreator<CommentsActionType.SET_COMMENTS, CommentsState['items']>(
  CommentsActionType.SET_COMMENTS
);

export const updateComments = generateActionCreator<CommentsActionType.UPDATE_COMMENTS, any>(
  CommentsActionType.UPDATE_COMMENTS
);

export const updateComment = generateActionCreator<CommentsActionType.UPDATE_COMMENT, any>(
  CommentsActionType.UPDATE_COMMENT
);

/* Reducers */

const itemsReducer = (
  state: CommentsState['items'] = INITIAL_STATE['items'],
  action: {
    type: CommentsActionType;
    payload: any;
  }
): CommentsState['items'] => {
  const { payload = [] } = action;
  if (action.type === CommentsActionType.SET_COMMENTS) {
    return payload.reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === CommentsActionType.UPDATE_COMMENTS) {
    return payload.reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), state);
  } else if (action.type === CommentsActionType.UPDATE_COMMENT) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...payload,
      },
    };
  }
  return state;
};

const commentsReducer = combineReducers({
  items: itemsReducer,
});

export default commentsReducer;

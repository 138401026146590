/* External dependencies */
import { Amplify } from 'aws-amplify';

export enum ApiName {
  bmApp = 'bmApp',
}

Amplify.configure({
  API: {
    REST: {
      [ApiName.bmApp]: {
        endpoint: `${process.env.REACT_APP_API_GATEWAY_URL}`,
        region: process.env.REACT_APP_REGION,
      },
    },
  }
});

const existingConfig = Amplify.getConfig();

const AWS_CONFIG = {
  Auth: {
    Cognito: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: 'us-west-2', // hard-code region for user pool
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      username: 'true',
      // refreshHandlers: {
      //   facebook: refreshToken,
      // }
      allowGuestAccess: true,
    },
  },
  API: {
    ...existingConfig.API,
  },
};

const amplifyInit = () => {
  Amplify.configure(AWS_CONFIG as any);
};

export default amplifyInit;

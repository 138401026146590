/* External dependencies */
import {
  fetchUserAttributes,
  getCurrentUser as getCurrentAuthUser,
  fetchAuthSession,
} from 'aws-amplify/auth';
import Cognito from 'aws-sdk/clients/cognitoidentity';
import get from 'lodash/get';

const cognitoIdentity = new Cognito({ region: 'us-west-2' });

export const getCurrentUserInfo = async () => {
  const {
    username,
    userId: id
  } = await getCurrentAuthUser();

  const attributes = fetchUserAttributes();

  return {
    id,
    username,
    attributes
  };
};

export const getAuthenticatedUser = async () => {
  try {
    const {
      username,
      signInDetails
    } = await getCurrentAuthUser();

    const {
      tokens: session,
      credentials,
    } = await fetchAuthSession();

    console.log('username', username);
    console.log('signInDetails', signInDetails);
    console.log('session', session);
    console.log('credentials', credentials);

    // Note that session will no longer contain refreshToken and clockDrift
    return {
      username,
      session,
      authenticated: true,
      identityId: get(credentials, 'identityId'),
      authenticationFlowType: get(signInDetails, 'authFlowType'),
    };
  } catch (e: any) {
    if (e.name === 'UserUnAuthenticatedException') {
      return { authenticated: false };
    }
  }
};

export const getIdentityId = async (): Promise<string | undefined> => {
  try {
    let [authUser] = await Promise.all([
      getAuthenticatedUser(),
    ]);

    let identityId = get(authUser, 'identityId');

    console.log('initial getIdentityId', identityId);

    if (!identityId) {
      const session = get(authUser, 'session');
      let token = session?.idToken!.toString();
      console.log('token', token);
      console.log('process.env.REACT_APP_USER_POOL_ID', process.env.REACT_APP_USER_POOL_ID);

      const { IdentityId } = await cognitoIdentity
        .getId({
          AccountId: process.env.REACT_APP_ACCOUNT_ID || '',
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || '',
          Logins: {
            [`cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: token!,
          },
        })
        .promise();
      identityId = IdentityId!;
    }

    return identityId;
  } catch (e: any) {
    console.log('getIdentityId error', e);
    console.log('getIdentityId error message', e.message);
  }
  return;
};
/* External dependencies */
import { combineReducers, Reducer } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';
import { PaginationOptions } from 'src/types/Pagination';

export enum ActionType {
  SET_ITEMS = 'reducer/{{paginatorId}}/SET_ITEMS',
  REMOVE_ITEM = 'reducer/{{paginatorId}}/REMOVE_ITEM',
  PREPEND_ITEM = 'reducer/{{paginatorId}}/PREPEND_ITEM',
  APPEND_ITEM = 'reducer/{{paginatorId}}/APPEND_ITEM',
}

export type ItemsState = {
  items: any[];
} & PaginationOptions;

export const INITIAL_STATE = {
  items: [],
};

type Action = {
  type: ActionType;
  payload: any;
};

class Paginator {
  paginatorId: string;
  reducer: any;
  reducers: any;
  getItems: any;
  setItems: any;
  removeItem: any;
  prependItem: any;
  appendItem: any;

  constructor(paginatorId: string, options: { reducers?: Object } = {}) {
    const { reducers = {} } = options;
    this.paginatorId = paginatorId;
    this.reducers = reducers;
    this.init();
  }

  init = () => {
    const { getItems } = this.createSelectors();
    const {
      setItems,
      removeItem,
      prependItem,
      appendItem,
    } = this.createActionCreators();
    this.reducer = this.createReducer();
    this.getItems = getItems;
    this.setItems = setItems;
    this.removeItem = removeItem;
    this.prependItem = prependItem;
    this.appendItem = appendItem;
  };

  static getActionType = (paginatorId: string, actionType: ActionType) => {
    return actionType.replace('{{paginatorId}}', paginatorId);
  };

  getActionType = (actionType: ActionType) => {
    const paginatorId = this.paginatorId;
    return Paginator.getActionType(paginatorId, actionType);
  };

  createReducer = () => {
    const itemsReducer = (
      state: ItemsState['items'] = INITIAL_STATE['items'],
      action: {
        type: ActionType;
        payload: any;
      }
    ) => {
      const { payload } = action;
      if (action.type === this.getActionType(ActionType.SET_ITEMS)) {
        return [...payload];
      } else if (action.type === this.getActionType(ActionType.REMOVE_ITEM)) {
        const { id: itemId } = payload;
        const itemIndex = state.findIndex(({ id }) => id === itemId);
    
        if (itemIndex === -1) {
          return state;
        }
    
        return [
          ...state.slice(0, itemIndex),
          ...state.slice(itemIndex + 1),
        ];
      } else if (action.type === this.getActionType(ActionType.PREPEND_ITEM)) {
        return [
          payload,
          ...state,
        ];
      } else if (action.type === this.getActionType(ActionType.APPEND_ITEM)) {
        return [
          ...state,
          payload,
        ];
      }

      return state;
    };
    const reducer = combineReducers({
      items: itemsReducer,
      ...this.reducers,
    });

    return reducer;
  };

  createSelectors = () => {
    const paginatorId = this.paginatorId;
    const getItems = (state: ApplicationState) => (state as any)[paginatorId as any].items;

    return {
      getItems,
    };
  };

  createActionCreators = () => {
    const setItems = generateActionCreator(this.getActionType(ActionType.SET_ITEMS));
    const removeItem = generateActionCreator(this.getActionType(ActionType.REMOVE_ITEM));
    const prependItem = generateActionCreator(this.getActionType(ActionType.PREPEND_ITEM));
    const appendItem = generateActionCreator(this.getActionType(ActionType.APPEND_ITEM));

    return {
      setItems,
      removeItem,
      prependItem,
      appendItem,
    };
  };
}

export default Paginator;
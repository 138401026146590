/* Internal dependencies */
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import get from 'lodash/get';

/* Internal dependencies */
// import Analytics, { AnalyticsEventType } from '../../analytics/Analytics';
import { ApplicationState } from 'src/store';
import { CurrentUserState, getCurrentUser } from 'src/store/ducks/currentUser';
import Icon, { Icons } from '../icon/Icon';
import { addMemberForCommunity, removeMemberForCommunity } from 'src/api/communities';
import { getCommunity, updateCommunity } from 'src/store/ducks/communities';
import { addNotification } from 'src/store/ducks/notifications';
import { ParticipantStatus } from 'src/types/Participant';
import AuthModalButton from 'src/authModal/AuthModalButton';
import Button from 'src/button/Button';
import Colors from 'src/colors';
import { Notification } from 'src/types/Notification';

type OwnProps = {
  community: any;
  iconSize?: number;
  showIcon?: boolean;
  render?: (params: { onClick: Function; loading: boolean; participantStatus: string; }) => React.ReactNode;
  activeBorderColor?: Colors;
  inactiveBorderColor?: Colors;
  activeBackgroundColor?: Colors;
  inactiveBackgroundColor?: Colors;
  activeTextColor?: Colors;
  inactiveTextColor?: Colors;
  fontSize?: string;
  width?: number | string;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
  updateCommunity(community: any): void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  loading: boolean;
};

const JoinCommunityButton: React.FC<Props> = ({
  currentUser, community, showIcon = true,
  iconSize = 22,
  activeBorderColor = Colors.white,
  inactiveBorderColor = Colors.inactiveTab,
  activeBackgroundColor = Colors.transparent,
  inactiveBackgroundColor = Colors.primary,
  activeTextColor = Colors.white,
  inactiveTextColor = Colors.white,
  fontSize = '1.25rem',
  width,
  render,
  updateCommunity,
  addNotification,
}) => {
  const [loading, setLoading] = useState<State['loading']>(false);

  const currentUserId = get(currentUser, 'id')!;
  const creators = get(community, 'creators');
  const participantStatus = get(community, 'participantStatus');
  const membershipType = get(community, 'membershipType');
  const creator = get(creators, 'items[0]');
  const isCreator = Boolean(currentUserId) && currentUserId === get(creator, 'id');

  const isPending = participantStatus === ParticipantStatus.pending && !isCreator;
  const isConfirmed = participantStatus === ParticipantStatus.confirmed;
  let joinText = 'Ask to join';

  if (membershipType === 'open') {
    joinText = 'Join';
  }

  const handleJoin = useCallback(async () => {
    const { membershipType } = community;
    const participantStatus = membershipType === 'open' ? ParticipantStatus.confirmed : ParticipantStatus.pending;
    addMemberForCommunity(community.id, currentUserId, participantStatus);

    if (membershipType === 'open') {
      updateCommunity({
        ...community,
        members: {
          ...community.members,
          items: [
            ...community.members.items,
            currentUser,
          ],
          total: community.members.items.length + 1,
        },
        participantStatus,
      });
    } else {
      updateCommunity({
        ...community,
        requests: {
          ...community.requests,
          items: [
            ...community.requests.items,
            currentUser,
          ],
          total: community.requests.items.length + 1,
        },
        participantStatus,
      });
    }

    // Analytics.track(AnalyticsEventType.participantRequested, { communityId: community.id, participantUserId: currentUser.id });

    addNotification({
      title: participantStatus === ParticipantStatus.confirmed ? 'Joined community' : 'Request sent',
      message: participantStatus === ParticipantStatus.confirmed ? `You've joined ${get(community, 'name')}` : `You've asked to join ${get(community, 'name')}`,
      variant: 'primary',
      items: [community],
    });
  }, [community, currentUserId]);

  const handleLeaveCommunity = useCallback(async () => {
    const newCommunity = await removeMemberForCommunity(community.id, currentUserId);
    const members = get(community, 'members.items', []);
    const memberIndex = members.findIndex((user: any) => user.id === currentUserId);
    const requests = get(community, 'requests.items', []);
    const requestIndex = requests.findIndex((user: any) => user.id === currentUserId);

    updateCommunity({
      ...community,
      members: {
        ...community.members,
        items: [
          ...community.members.items.slice(0, memberIndex),
          ...community.members.items.slice(memberIndex + 1),
        ],
        total: community.members.items.length - 1,
      },
      requests: {
        ...community.requests,
        items: [
          ...community.requests.items.slice(0, requestIndex),
          ...community.requests.items.slice(requestIndex + 1),
        ],
        total: community.requests.items.length - 1,
      },
      participantStatus: undefined,
    });

    // Analytics.track(AnalyticsEventType.participantLeft, { communityId: community.id, participantUserId: currentUserId });

    addNotification({
      title: 'Left community',
      message: 'Left community.',
      variant: 'primary',
      items: [newCommunity],
    });
  }, [community, currentUserId]);

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);

      if (!participantStatus) {
        return await handleJoin();
      } else {
        return await handleLeaveCommunity();
      }
    } catch (e) {
      console.log('handleClick error: ', e);
    } finally {
      setLoading(false);
    }
  }, [participantStatus]);

  if (!community) return null;

  if (isCreator) return null;

  return (
    <AuthModalButton
      modalHeader={`Register / Sign in to join ${get(community, 'name', 'this community')}`}
      onClick={handleClick}
      render={(onClick) => {

        if (render) {
          return render({ onClick, participantStatus, loading });
        }

        return (
          <Button
            disabled={!community}
            className="btn btn-block d-flex justify-content-center align-items-center pl-3 pr-3"
            variant="primary"
            onClick={onClick}
            style={{
              width,
              borderRadius: 100,
              borderColor: isConfirmed ? activeBorderColor : inactiveBorderColor,
              borderWidth: isConfirmed ? 2.5 : 0,
              backgroundColor: isConfirmed ? activeBackgroundColor : inactiveBackgroundColor,
              maxHeight: 'none', maxWidth: 'none',
            }}
          >
            <div className="d-flex align-items-center">
              {Boolean(showIcon) && (
                <div className="mr-2 pb-1">
                  {Boolean(isPending || isConfirmed) ? (
                    <Icon name={Icons.check} className={isPending ? '' : 'bm-Icon--white'} solid size={iconSize} />
                  ) : (
                    <Icon name={Icons.plus} className="bm-Icon--white" size={iconSize} />
                  )}
                </div>
              )}
              <h5
                className="m-0 p-0 pt-1 pb-1 text-bold"
                style={{ fontSize, color: isPending || isConfirmed ? activeTextColor : inactiveTextColor }}
              >
                {isPending ? 'Requested' : isConfirmed ? 'Joined' : joinText}
              </h5>
            </div>
          </Button>
        );
      }}
    />
  );
};

const mapStateToProps = (state: ApplicationState, { community }: OwnProps) => {
  const communityId = get(community, 'id');

  return {
    currentUser: getCurrentUser(state),
    community: getCommunity(state, communityId) || community,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
  updateCommunity: (community: any) => { dispatch(updateCommunity(community)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinCommunityButton);
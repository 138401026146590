/* External dependencies */
import { ActionCreator, Reducer } from 'redux';

export const generateActionCreator = <TActionType, TDataType>(
  type: TActionType
): ActionCreator<{ type: TActionType; payload: TDataType }> => {
  interface Action {
    type: TActionType;
    payload: TDataType;
  }
  return (payload: TDataType): Action => ({ type, payload });
};

export const generateReducer = <TActionType, TDataType>(
  actionType: TActionType,
  initialState: TDataType
): Reducer<TDataType, { type: TActionType; payload: TDataType }> => {
  interface Action {
    type: TActionType;
    payload: TDataType;
  }

  return (state: TDataType = initialState, action: Action): TDataType => {
    const { payload } = action;
    if (action.type === actionType) {
      return payload;
    }
    return state;
  };
};

/* Internal dependencies */
import API from '.';

export enum Referrer {
  // Internal
  beatmatch = 'beatmatch',

  // Social
  facebook = 'facebook',
  instagram = 'instagram',
  tiktok = 'tiktok',
  x = 'x',
  twitter = 'twitter',

  // Search
  google = 'google',
  bing = 'bing',
  yahoo = 'yahoo',
  duckduckgo = 'duckduckgo',
}

export enum ReferrerDomain {
  // Internal
  beatmatch = 'beatmatch.app',

  // Social
  facebook = 'facebook.com',
  instagram = 'instagram.com',
  tiktok = 'tiktok.com',
  x = 'x.com',
  twitter = 'twitter.com',

  // Search
  google = 'twitter.com',
  bing = 'bing.com',
  yahoo = 'yahoo.com',
  duckduckgo = 'duckduckgo.com',

  // Local
  localhost = 'localhost',
}

export enum ReferrerCategory {
  direct = 'direct',
  social = 'social',
  search = 'search',
  internal = 'internal',
  local = 'local',
}

type ViewOptions = {
  referrer: Referrer;
  referrerCategory: ReferrerCategory;
  referrerUrl: string;
  // referrerPageId?: string;
};

export const addViewForItem = async (userId: string, itemId: string, options: ViewOptions | {} = {}) =>
  await API.post({
    path: `/users/${userId}/views/items/${itemId}`,
    options: {
      body: options
    },
  });
/* External dependencies */
import { combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator, generateReducer } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum EventsActionType {
  SET_EVENTS = 'reducer/events/SET_EVENTS',
  SET_COLLECTIONS = 'reducer/events/SET_COLLECTIONS',
  SET_OFFSET = 'reducer/events/SET_OFFSET',
  SET_EVENTS_LOADING = 'reducer/events/SET_EVENTS_LOADING',
  UPDATE_EVENT = 'reducer/events/UPDATE_EVENT',
  UPDATE_EVENTS = 'reducer/events/UPDATE_EVENTS',
}

export type EventsState = {
  items: { [eventId: string]: any };
  collections: any[];
  offset: Object | null;
  loading: boolean;
};

export const INITIAL_STATE = {
  items: {},
  collections: [],
  offset: null,
  loading: true,
};

/* Selectors */
export const getEvents = (state: ApplicationState) => Object.values(state.events.items);
export const getEvent = (state: ApplicationState, eventId: string) => state.events.items[eventId];
export const getCollections = (state: ApplicationState) => state.events.collections;
export const getOffset = (state: ApplicationState) => state.events.offset;
export const getEventsLoading = (state: ApplicationState) => state.events.loading;

/* Action Creator */
export const setEvents = generateActionCreator<EventsActionType.SET_EVENTS, EventsState['items']>(
  EventsActionType.SET_EVENTS
);

export const updateEvent = generateActionCreator<EventsActionType.UPDATE_EVENT, any>(
  EventsActionType.UPDATE_EVENT
);

export const updateEvents = generateActionCreator<EventsActionType.UPDATE_EVENTS, any[]>(
  EventsActionType.UPDATE_EVENTS
);

export const setCollections = generateActionCreator<EventsActionType.SET_COLLECTIONS, EventsState['collections']>(
  EventsActionType.SET_COLLECTIONS
);

export const setOffset = generateActionCreator<EventsActionType.SET_OFFSET, EventsState['offset']>(
  EventsActionType.SET_OFFSET
);

export const setEventsLoading = generateActionCreator<EventsActionType.SET_EVENTS_LOADING, EventsState['loading']>(
  EventsActionType.SET_EVENTS_LOADING
);

/* Reducers */
const itemsReducer = (
  state: EventsState['items'] = INITIAL_STATE['items'],
  action: {
    type: EventsActionType;
    payload: any;
  }
): EventsState['items'] => {
  const { payload } = action;
  if (action.type === EventsActionType.SET_EVENTS) {
    return payload.reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === EventsActionType.UPDATE_EVENT) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...state[id],
        ...payload,
      },
    };
  } else if (action.type === EventsActionType.UPDATE_EVENTS) {
    const events = payload;

    return {
      ...state,
      ...events.reduce((acc: any, curr: any) => {
        const { id } = curr;

        return {
          ...acc,
          [id]: {
            ...state[id],
            ...curr,
          },
        };
      }, {}),
    };
  }

  return state;
};

const collectionsReducer = generateReducer<EventsActionType.SET_COLLECTIONS, EventsState['collections']>(
  EventsActionType.SET_COLLECTIONS,
  INITIAL_STATE.collections
);

const offsetReducer = generateReducer<EventsActionType.SET_OFFSET, EventsState['offset']>(
  EventsActionType.SET_OFFSET,
  INITIAL_STATE.offset
);

const loadingReducer = generateReducer<EventsActionType.SET_EVENTS_LOADING, EventsState['loading']>(
  EventsActionType.SET_EVENTS_LOADING,
  INITIAL_STATE.loading
);

const eventsReducer = combineReducers({
  items: itemsReducer,
  collections: collectionsReducer,
  offset: offsetReducer,
  loading: loadingReducer,
});

export default eventsReducer;

/* Internal dependencies */
import { cacheify } from '../helpers/cacheify';
import { PaginationOptions } from '../types/Pagination';
import API from '.';

export const listFriendsForUser = async (userId: string, options: PaginationOptions) =>
  await API.get({
    path: `/users/${userId}/friends`,
    options: {
      queryParams: options,
    },
  });

export const listFriendRequestsForUser = async (userId: string, options: PaginationOptions) =>
  await API.get({
    path: `/users/${userId}/friendRequests`,
    options: {
      queryParams: options,
    },
  });

const cachedSearchFriends = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(
  async (q: string, { limit, offset }: PaginationOptions) =>
    await API.get({
      path: '/search',
      options: {
        queryParams: {
          q,
          type: 'friend',
          limit,
          offset,
        },
      },
    }) as any,
  60000
);

export const searchFriends = async (q: string, options: PaginationOptions) => await cachedSearchFriends()(q, options);

/* External dependencies */
import { createTransform } from 'redux-persist';
import LZString from 'lz-string';

const transform = createTransform(
  // Transform state on its way to being serialized and persisted
  (inboundState) => LZString.compress(JSON.stringify(inboundState)),
  // Transform state being rehydrated
  (outboundState) => JSON.parse(LZString.decompress(outboundState))
);

export default transform;
/* Internal dependencies */
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import get from 'lodash/get';

/* Internal dependencies */
import { ApplicationState } from 'src/store';
import Icon, { Icons } from 'src/icon/Icon';
import Colors from 'src/colors';
import Button from 'src/button/Button';
import AuthModalButton from 'src/authModal/AuthModalButton';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';
import { getArtist } from 'src/store/ducks/artists';
import { toggleArtist } from 'src/store/stories/artists';
import { cleanObject } from 'src/common/cleanObject';
import Analytics, { AnalyticsEventType } from 'src/analytics/Analytics';
import { fetchAll } from 'src/common/helpers/fetchAll';

type OwnProps = {
  artist: any;
  iconSize?: number;
  showIcon?: boolean;
  render?: (params: { onClick: Function; loading: boolean; added: boolean; }) => React.ReactNode;
  activeBorderColor?: Colors;
  inactiveBorderColor?: Colors;
  activeBackgroundColor?: Colors;
  inactiveBackgroundColor?: Colors;
  activeTextColor?: Colors;
  inactiveTextColor?: Colors;
  fontSize?: string;
  width?: number | string;
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & DispatchProps;

type State = {
  loading: boolean;
};

const FollowArtistButton: React.FC<Props> = ({
  artist, showIcon = true,
  iconSize = 22,
  render,
  activeBorderColor = Colors.white,
  inactiveBorderColor = Colors.inactiveTab,
  activeBackgroundColor = Colors.transparent,
  inactiveBackgroundColor = Colors.primary,
  activeTextColor = Colors.white,
  inactiveTextColor = Colors.white,
  fontSize = '1.25rem',
  width
}) => {
  const [loading, setLoading] = useState<State['loading']>(false);

  const artistId = get(artist, 'id');
  const artistAdded = get(artist, 'added', false);

  const handleFollow = useCallback(async () => {
    try {
      setLoading(true);
      const promises: Promise<any>[] = [];

      if (artistAdded) {
        promises.push(Analytics.track(AnalyticsEventType.artistRemoved, { artistId }));
      } else {
        promises.push(Analytics.track(AnalyticsEventType.artistAdded, { artistId }));
      }

      promises.push(toggleArtist(artist));

      return await fetchAll(promises);
    } finally {
      setLoading(false);
    }
  }, [artistId, artistAdded, artist]);

  return (
    <AuthModalButton
      modalHeader={`Register / Sign in to follow ${get(artist, 'name', 'artist')}`}
      modalSubheader="Get notified about upcoming events, meet fans near you, and support the artist."
      onClick={handleFollow}
      render={(onClick) => {

        if (render) {
          return render({ onClick, added: artistAdded, loading });
        }

        return (
          <Button
            disabled={!artist}
            className="btn btn-block d-flex justify-content-center align-items-center"
            variant="primary"
            onClick={onClick}
            style={cleanObject({
              width,
              borderRadius: 100,
              borderColor: Boolean(artistAdded) ? activeBorderColor : inactiveBorderColor,
              borderWidth: Boolean(artistAdded) ? 2.5 : 0,
              backgroundColor: Boolean(artistAdded) ? activeBackgroundColor : inactiveBackgroundColor,
            })}
          >
            <div className="d-flex align-items-center">
              {Boolean(showIcon) && (
                <div className="mr-2">
                  {Boolean(artistAdded) ? (
                    <Icon name={Icons.check} className="bm-Icon--white" solid size={iconSize} />
                  ) : (
                    <Icon name={Icons.plus} className="bm-Icon--white" size={iconSize} />
                  )}
                </div>
              )}
              <h5 className="p-0 m-0 text-bold" style={{ fontSize, color: Boolean(artistAdded) ? activeTextColor : inactiveTextColor }}>{Boolean(artistAdded) ? 'Following' : 'Follow'}</h5>
            </div>
          </Button>
        );
      }}
    />
  );
};

const mapStateToProps = (state: ApplicationState, { artist }: OwnProps) => {
  const artistId = get(artist, 'id');

  return {
    artist: getArtist(state, artistId) || artist,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => {
    dispatch(addNotification(notification));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowArtistButton);
/* Internal dependencies */
import API from '.';
import { PaginationOptions } from 'src/types/Pagination';

export const listContactsForUser = async (userId: string, { limit, offset, q }: PaginationOptions & { q?: string; }) =>
  await API.get({
    path: `/users/${userId}/contacts`,
    options: {
      queryParams: {
        limit,
        offset,
        q,
      },
    },
  });
/* External dependencies */
import moment from 'moment';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isDate from 'lodash/isDate';
import { AnalyticsBrowser } from '@segment/analytics-next';
import branch from 'branch-sdk';

/* Internal dependencies */
import { fetchAll } from '../common/helpers/fetchAll';
import User from '../types/User';
import { cleanObject } from 'src/common/cleanObject';

export enum AnalyticsEventType {
  facebookSignInInitiated = 'FACEBOOK_SIGN_IN_INITIATED',
  facebookSignInNewUser = 'FACEBOOK_SIGN_IN_NEW_USER',
  facebookSignInExistingUser = 'FACEBOOK_SIGN_IN_EXISTING_USER',
  facebookSignInCanceled = 'FACEBOOK_SIGN_IN_CANCELED',
  facebookSignInFailed = 'FACEBOOK_SIGN_IN_FAILED',
  facebookSignInFinished = 'FACEBOOK_SIGN_IN_FINISHED',
  userSignedUp = 'User Signed Up',
  userSignedIn = 'User Signed In',
  userAbandoned = 'User Abandoned',
  userBlocked = 'User Blocked',
  userUnblocked = 'User Unblocked',
  userLiked = 'User Liked',
  userPassed = 'User Passed',
  userMatched = 'User Matched',
  userDeleted = 'User Deleted',
  messageSent = 'Message Sent',
  chatAccepted = 'Chat Accepted',
  chatRequested = 'Chat Requested',
  cohostRequestAccepted = 'Cohost Request Accepted',
  cohostRequestRejected = 'Cohost Request Rejected',
  cohostRequestSent = 'Cohost Request Sent',
  cohostRemoved = 'Cohost Removed',
  teamMemberRequestAccepted = 'Team Member Request Accepted',
  teamMemberRequestRejected = 'Team Member Request Rejected',
  teamMemberRequestSent = 'Team Member Request Sent',
  teamMemberRemoved = 'Team Member Removed',
  friendRequestAccepted = 'Friend Request Accepted',
  friendRequestRejected = 'Friend Request Rejected',
  friendRequestSent = 'Friend Request Sent',
  friendRemoved = 'Friend Removed',
  friendRequestRescinded = 'Friend Request Rescinded',
  eventAbandoned = 'Event Abandoned',
  eventAdded = 'Event Added',
  eventRemoved = 'Event Removed',
  eventUpdated = 'Event Updated',
  eventCreated = 'Event Created',
  eventSaved = 'Product Added to Wishlist',
  eventViewed = 'Product Viewed',
  eventCarted = 'Product Added',
  artistAdded = 'Artist Added',
  artistRemoved = 'Artist Removed',
  trackAdded = 'Track Added',
  trackRemoved = 'Track Removed',
  trackPaused = 'Track Paused',
  trackPlayed = 'Track Played',
  trackSeeked = 'Track Seeked',
  trackMuted = 'Track Muted',
  trackUnmuted = 'Track Unmuted',
  desireAdded = 'Desire Added',
  desireRemoved = 'Desire Removed',
  meetupCreated = 'Plan Created',
  participantRequested = 'Participant Requested',
  participantInvited = 'Participant Invited',
  participantInvitationAccepted = 'Participant Invitation Accepted',
  participantInvitationRejected = 'Participant Invitation Rejected',
  participantConfirmed = 'Participant Confirmed',
  participantRejected = 'Participant Rejected',
  participantRemoved = 'Participant Removed',
  participantLeft = 'Participant Left',
  participantInvitationRescinded = 'Participant Invitation Rescinded',
  photoAdded = 'Photo Added',
  photoReplaced = 'Photo Replaced',
  contentShared = 'Content Shared',
  externalLinkClicked = 'External Link Clicked',
  activityCreated = 'Plan Created',
  activityUpdated = 'Plan Updated',
  activityDeleted = 'Plan Deleted',
  activityLiked = 'Plan Liked',
  activityUnliked = 'Plan Unliked',
  communityCreated = 'Community Created',
  communityUpdated = 'Community Updated',
  communityDeleted = 'Community Deleted',
  communityAbandoned = 'Community Abandoned',
  communityMemberJoined = 'Community Member Joined',
  communityMemberLeft = 'Community Member Left',
  communityMemberRequested = 'Community Member Requested',
  communityMemberConfirmed = 'Community Member Confirmed',
  communityMemberRejected = 'Community Member Rejected',
  communityMemberRemoved = 'Community Member Removed',
  guestCheckedIn = 'Guest Checked In',
  guestUncheckedIn = 'Guest Unchecked In',
  postCreated = 'Post Created',
  postUpdated = 'Post Updated',
  postDeleted = 'Post Deleted',
  postLiked = 'Post Liked',
  postUnliked = 'Post Unliked',
  commentSent = 'Comment Sent',
  commentLiked = 'Comment Liked',
  commentUnliked = 'Comment Unliked',
  replySent = 'Reply Sent',
  contactsConnected = 'Contacts Connected',
  search = 'Products Searched',
  onboardingStepSkippped = 'Onboarding Step Skipped',
  onboadingStepPledged = 'Onboarding Step Pledged',
  feedItemIndexChanged = 'Feed Item Index Changed',
  feedItemClicked = 'Feed Item Clicked',
  orderCompleted = 'Order Completed',
  checkoutStarted = 'Checkout Started',
  checkoutStepViewed = 'Checkout Step Viewed',
  checkoutStepCompleted = 'Checkout Step Completed',
  venueFollowed = 'Venue Followed',
  venueUnfollowed = 'Venue Unfollowed',
}

enum Screens {
  Event = 'Event',
}

export enum AuthenticationMethod {
  facebook = 'facebook',
  apple = 'apple',
  phoneNumber = 'phone_number',
}

export type AnalyticsEvent = {
  type: AnalyticsEventType;
  payload?: Object;
};

const segment = new AnalyticsBrowser();
segment
  .load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY! })
  .catch((e) => {
    console.log('Error loading Segment client: ', e);
  });

class Analytics {
  // static getBranchUniversalObject = async () => {
  //   const branchClient = await branch.createBranchUniversalObject(`beatmatch`, {});

  //   return branchClient;
  // };

  static track = async (type: AnalyticsEventType, payload?: AnalyticsEvent['payload']) => {
    try {
      await fetchAll([
        // analytics().logEvent((type as string || '').replaceAll(' ', '_'), payload),
        segment.track(type, payload as any), // Segment is really the only one needed. The others can be setup as destinations in Segment
      ]);
    } catch (e) {
      console.log('Failed to log event for analytics: ', e);
    }
  };

  static identify = async (userId: string, user: User) => {
    try {
      const {
        id, name, firstName, lastName, email,
        phone, gender, birthday, createdAt,
        images = [], location, bio, friendsTotal,
      } = user;
      const bDay = moment(birthday);
      const age = moment().diff(bDay, 'years');
      const currentCandidateJobs = get(user, 'work.jobs', []);
      const currentCandidateActiveJobIndex = get(user, 'work.activeJob', 0);
      const currentCandidateActiveJob = currentCandidateJobs[currentCandidateActiveJobIndex];
      const latitude = get(location, 'latitude');
      const longitude = get(location, 'longitude');

      try {
        branch.setIdentity(userId);
      } catch (e) {
        console.log('Error calling Branch setIdentity', e);
      }

      return await fetchAll([
        segment.identify(userId, cleanObject({
          id,
          name,
          firstName: get(user, 'firstName', name),
          first_name: get(user, 'firstName', name),
          lastName,
          last_name: lastName,
          phone,
          gender,
          createdAt: isString(createdAt) ? createdAt : isDate(createdAt) ? createdAt.toISOString() : undefined,
          age,
          email,
          // hasBio: Boolean(bio),
          birthday: isString(birthday) ? birthday : isDate(birthday) ? birthday.toISOString() : undefined, 
          avatar: get(images, '[0].url'),
          image_url: get(images, '[0].url'),
          company: { name: get(currentCandidateActiveJob, 'company') },
          home_city: get(location, 'city'),
          current_location: {
            latitude: Number.parseFloat(latitude as any),
            longitude: Number.parseFloat(longitude as any),
          },
          address: {
            street: get(location, 'address1'),
            city: get(location, 'city'),
            state: get(location, 'state'),
            country: get(location, 'country'),
            postalCode: get(location, 'zipCode'),
          },
          // artistIds: Array.from(user.artistIds).sort().slice(0,100),
          friendsTotal,
          email_subscribe: 'opted_in', 
          push_subscribe: 'opted_in',
        })),
        // analytics().setUserProperties(removeNonStringProperties(user)),
      ]);
    } catch (e) {
      console.log('Failed to identify user for analytics: ', e);
    }
  };

  static trackLogin = async ({ method, userId, email, phone }: { method: AuthenticationMethod; userId: string; email?: string; phone?: string; }) => {
    const branchParams = {
      alias: email || phone,
      transaction_id: new Date().getTime(),
      registration_id: userId,
      customData: cleanObject({ userId, email: email!, phone: phone! }) as Object,
    };

    branch.logEvent('LOGIN', branchParams);

    return await fetchAll([
      // analytics().logLogin({ method }),
      Analytics.track(AnalyticsEventType.userSignedIn, { method }),
    ]);
  };

  static trackSignUp = async ({ method, userId, email, phone }: { method: AuthenticationMethod; userId: string; email?: string; phone?: string; }) => {
    const branchParams = {
      alias: email || phone,
      transaction_id: new Date().getTime(),
      registration_id: userId,
      customData: cleanObject({ userId, email: email!, phone: phone! }) as Object,
    };

    branch.logEvent('COMPLETE_REGISTRATION', branchParams);

    return await fetchAll([
      // analytics().logSignUp({ method }),
      Analytics.track(AnalyticsEventType.userSignedUp, { method }),
    ]);
  };

  static trackSearch = async ({ searchQuery, searchType }: { searchQuery: string, searchType?: string; }) => {
    const branchParams = {
      transaction_id: new Date().getTime(),
      searchQuery,
    };
    
    branch.logEvent('SEARCH', branchParams);

    return await fetchAll([
      // analytics().logSearch({ search_term: searchQuery }),
      Analytics.track(AnalyticsEventType.search, { searchQuery, searchType }),
    ]);
  };

  static trackScreenView = async (screenName: Screens, custom: Object = {}) => {
    const promises: Promise<any>[] = [
      segment.page(screenName as string),
      // Legacy. Keep this until fully integrating Segement w/ Firebase
      // analytics().logScreenView({
      //   screen_name: screenName,
      //   screen_class: screenName,
      // }),
    ];

    if (screenName === Screens.Event) {
      const eventId = get(custom, 'eventId');

      promises.push(Analytics.track(AnalyticsEventType.eventViewed, {
        ...custom,
        screenName,
        content_ids: [eventId],
        content_type: 'product',
      }))
    }

    return await fetchAll(promises);
  };

  static trackContentShare = async (item: { id?: string; name?: string; type: string; }, options: { method?: string; } = {}) => {
    const { id, type, name } = item;
    const { method = 'n/a' } = options;

    return await Analytics.track(AnalyticsEventType.contentShared, {
      contentId: id,
      contentType: type,
      contentName: name,
      method,
    });
  };

  static trackExternalLinkClick = async (item: { id?: string; name?: string; type: string; url?: string; custom?: Object }) => {
    const { id, type, name, url, custom } = item;
    
    return await Analytics.track(AnalyticsEventType.externalLinkClicked, {
      contentId: id,
      contentType: type,
      contentName: name,
      contentUrl: url,
      custom,
    });
  };

  static trackMessageSent = async (item: { chatId: string; senderId: string; recipientId?: string; characterCount: number; }) => {
    const { chatId, senderId, recipientId, characterCount } = item;

    return await Analytics.track(AnalyticsEventType.messageSent, {
      messageChatId: chatId,
      messageSenderId: senderId,
      messageRecipientId: recipientId,
      messageCharacterCount: characterCount,
    });
  };
}

export default Analytics;

const wrap = async (promise: Promise<any>) => {
  try {
    const response = await promise;
    return response;
  } catch (e) {
    console.log('fetchAll wrap error', JSON.stringify(e));
    return e;
  }
};

export const fetchAll = async (promises: Promise<any>[]): Promise<any[]> => {
  try {
    const wrappedPromises = await promises.map(wrap);
    const responses = await Promise.all(wrappedPromises);
    return responses;
  } catch (e) {
    console.log('fetchAll error');
  }

  return Promise.resolve([]);
};

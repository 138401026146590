/* External dependencies */
import { get, post, put, del } from 'aws-amplify/api';
import _get from 'lodash/get';

/* Internal dependencies */
import { ApiName } from '../aws-exports';
import { cleanObject } from 'src/common/cleanObject';

type APIParams = {
  apiName?: ApiName;
  path: string;
  options?: Object & {
    queryParams?: Object;
    body?: Object;
  };
};

class API {
  static async get<T = any>({
    apiName = ApiName.bmApp,
    path,
    options,
  }: APIParams) {
    try {
      const restOperation = get(cleanObject({
        apiName,
        path,
        options,
      }));
      const { body } = await restOperation.response;
      return (await body.json()) as any as T;
    } catch (e: any) {
      const body = _get(e, 'response.body');
      console.error('GET call failed: ', JSON.parse(body));
      throw new Error(await body.text());
    }
  }

  static async post<T = any>({
    apiName = ApiName.bmApp,
    path,
    options,
  }: APIParams) {
    try {
      const restOperation = post(cleanObject({
        apiName,
        path,
        options,
      }));
      const { body } = await restOperation.response;
      return (await body.json()) as any as T;
    } catch (e: any) {
      const body = _get(e, 'response.body');
      console.error('POST call failed: ', JSON.parse(body));
      throw new Error(await body.text());
    }
  }

  static async put<T = any>({
    apiName = ApiName.bmApp,
    path,
    options,
  }: APIParams) {
    try {
      const restOperation = put(cleanObject({
        apiName,
        path,
        options,
      }));
      const { body } = await restOperation.response;
      return (await body.json()) as any as T;
    } catch (e: any) {
      const body = _get(e, 'response.body');
      console.error('PUT call failed: ', JSON.parse(body));
      throw new Error(await body.text());
    }
  }

  static async del({
    apiName = ApiName.bmApp,
    path,
    options,
  }: APIParams) {
    try {
      const restOperation = del(cleanObject({
        apiName,
        path,
        options,
      }));
      return await restOperation.response;
    } catch (e: any) {
      const body = _get(e, 'response.body');
      console.error('DELETE call failed: ', JSON.parse(body));
      throw new Error(await body.text());
    }
  }
}

export default API;
/* External dependencies */
import { Action, combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum ArtistsActionType {
  SET_ARTISTS = 'reducer/artists/SET_ARTISTS',
  UPDATE_ARTIST = 'reducer/artists/UPDATE_ARTIST',
  UPDATE_ARTISTS = 'reducer/artists/UPDATE_ARTISTS',
}

export type ArtistsState = {
  items: { [artistId: string]: any };
};

export const INITIAL_STATE = {
  items: {},
};

/* Selectors */
export const getArtists = (state: ApplicationState) => Object.values(state.artists.items);
export const getArtist = (state: ApplicationState, artistId: string) => state.artists.items[artistId];

/* Action Creator */
export const setArtists = generateActionCreator<ArtistsActionType.SET_ARTISTS, ArtistsState['items']>(
  ArtistsActionType.SET_ARTISTS
);

export const updateArtist = generateActionCreator<ArtistsActionType.UPDATE_ARTIST, any>(
  ArtistsActionType.UPDATE_ARTIST
);

export const updateArtists = generateActionCreator<ArtistsActionType.UPDATE_ARTISTS, any[]>(
  ArtistsActionType.UPDATE_ARTISTS
);

/* Reducers */
const itemsReducerWrapper = (
  state: ArtistsState['items'] = INITIAL_STATE.items,
  action: Action & { payload: any }
): ArtistsState['items'] => {
  const { payload } = action;
  if (action.type === ArtistsActionType.SET_ARTISTS) {
    return payload.reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === ArtistsActionType.UPDATE_ARTIST) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...state[id],
        ...payload,
      },
    };
  } else if (action.type === ArtistsActionType.UPDATE_ARTISTS) {
    const activities = payload;

    return {
      ...state,
      ...activities.reduce((acc: any, curr: any) => {
        const { id } = curr;

        return {
          ...acc,
          [id]: {
            ...state[id],
            ...curr,
          },
        };
      }, {}),
    };
  }

  return state;
};

const artistsReducer = combineReducers({
  items: itemsReducerWrapper,
});

export default artistsReducer;

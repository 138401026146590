/* External dependencies */
import { Action } from 'redux';

/* Internal dependencies */
import store, { ApplicationState } from '.';

export const put = (action: Action): void => {
  store.dispatch(action);
};

export const select = <R, A extends any[]>(selector: (state: ApplicationState, ...args: A) => R, ...args: A) => {
  const state = store.getState();
  return selector(state, ...args);
};

const DEFAULT_FLOAT_DIGITS = 2;

export const randomNumber = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randomFloat = (min: number, max: number, options: { digits: number; } = { digits: DEFAULT_FLOAT_DIGITS }): number => {
  const { digits = DEFAULT_FLOAT_DIGITS } = options;
  const randomFloat = Math.random() * (max - min) + min;
  return parseFloat(randomFloat.toFixed(digits));
};

export const randomItem = <T = any>(items: T[]): T => {
  return items[Math.floor(Math.random() * items.length)];
};
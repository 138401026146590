/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { User, Image } from '../types/User';
import API from '.';
import { cleanObject } from 'src/common/cleanObject';
import { PaginationOptions } from 'src/types/Pagination';
import { listArtistsForUser } from './artists';
import { listCreatedEventsForUser, listEventsForUser, listPastEventsForUser } from './events';
import { listCommunitiesForUser, listCreatedCommunitiesForUser } from './communities';
import { listFriendRequestsForUser, listFriendsForUser } from './friends';
import { fetchAll } from 'src/common/helpers/fetchAll';
import { listAchievementGroupsForUser, listAchievementsForUser } from './achievements';
import { listOrders } from './orders';
import { listContactsForUser } from './contacts';
import { listNotificationsForUser } from './notifications';


export const getUser = async (userId: string, options?: { cache?: boolean; mini?: boolean; }): Promise<User> => {
  const cache = get(options, 'cache');
  const mini = get(options, 'mini');

  return await API.get({
    path: `/users/${userId}`,
    options: {
      queryParams: cleanObject({ cache, mini }),
    },
  }) as any as User;
};

export const getFullUser = async (userId: string): Promise<User> => {
  const promises = [
    getUser(userId, { mini: true }),
    listArtistsForUser(userId, { limit: 8 }),
    listEventsForUser(userId, { limit: 10 }),
    listPastEventsForUser(userId, { limit: 3 }),
    listCreatedEventsForUser(userId, { limit: 10 }),
    listCommunitiesForUser(userId, { limit: 5 }),
    listCreatedCommunitiesForUser(userId, { limit: 5 }),
    listFriendsForUser(userId, { limit: 5 }),
    listAchievementsForUser(userId, { limit: 5 }),
  ];

  const [
    user, artists, events, pastEvents, createdEvents,
    communities, createdCommunities,
    friends, achievements,
  ] = await fetchAll(promises);

  return {
    ...user,
    artists,
    events,
    pastEvents,
    createdEvents,
    communities,
    createdCommunities,
    friends,
    achievements,
  };
};

export const getCurrentFullUser = async (userId: string): Promise<User> => {
  const promises = [
    getFullUser(userId),
    listNotificationsForUser(userId, { limit: 10 }),
    listFriendRequestsForUser(userId, { limit: 5 }),
    listOrders({ limit: 5 }),
    listContactsForUser(userId, { limit: 5 }),
    listAchievementGroupsForUser(userId, { limit: 10 }),
  ];

  const [
    user, notifications, friendRequests,
    orders, contacts, achievementGroups,
  ] = await fetchAll(promises);

  return {
    ...user,
    notifications,
    friendRequests,
    orders,
    contacts,
    achievementGroups,
  };
};

export const createUser = async (user: Partial<User>) => {
  const response = await API.post({
    path: '/users',
    options: {
      body: user,
    },
  });

  return response as any as User;
};

export const addImageForUser2 = async (userId: string, image: Omit<Image, 'url'>) =>
  await API.post({
    path: `/users/${userId}/images2`,
    options: {
      body: image,
    },
  });

export const updateNameForUser = async (userId: string, name: string) =>
  await API.put({
    path: `/users/${userId}/name`,
    options: {
      body: { name },
    },
  });

export const updateEmailForUser = async (userId: string, email: string) =>
  await API.put({
    path: `/users/${userId}/email`,
    options: {
      body: { email },
    },
  });

export const deleteUser = async (userId: string) => await API.del({ path: `/users/${userId}` });

export const listUsers = async ({ limit = 15, offset }: PaginationOptions) =>
  await API.get({
    path: `/users`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: (Boolean(offset) && JSON.stringify(offset)) || undefined,
      }),
    },
  });

export const searchUsers = async (q: string, { limit = 15, offset }: PaginationOptions) =>
  await API.get({
    path: `/users`,
    options: {
      queryParams: cleanObject({
        q,
        limit,
        offset: (Boolean(offset) && JSON.stringify(offset)) || undefined,
      }),
    },
  });

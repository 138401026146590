/* Internal dependencies */
import { cacheify } from 'src/helpers/cacheify';
import { PaginationOptions } from 'src/types/Pagination';
import API from '.';
import { cleanObject } from 'src/common/cleanObject';

type LocationOptions = {
  longitude?: number;
  latitude?: number;
  range?: 'global' | 'local';
};

export const listLocations = async (limit: number = 30) =>
  await API.get({
    path: `/locations`,
    options: {
      queryParams: { limit },
    },
  });

export const getLocation = async (locationId: string) =>
  await API.get({
    path: `/locations/${locationId}`,
  });

export const listEventsForLocation = async (locationId: string, { limit, offset }: PaginationOptions) =>
  await API.get({
    path: `/locations/${locationId}/events2`,
    options: {
      queryParams: cleanObject({
        limit,
        offset: Boolean(offset) && JSON.stringify(offset),
      }),
    },
  });

export const locationView = async (
  locationId: string,
  params: PaginationOptions & { type: 'event' | 'neighborhood' | 'venue' }
) =>
  await API.get({
    path: `/locations/${locationId}/locationView`,
    options: {
      queryParams: cleanObject(params),
    },
  });

const cachedSearchLocations = cacheify<(...args: any[]) => Promise<{ items: any[] }>, { items: any[] }>(
  async (q: string, { limit, offset, latitude, longitude, range = 'local' }: PaginationOptions & LocationOptions) =>
    await API.get({
      path: '/search',
      options: {
        queryParams: {
          q,
          type: 'location',
          provider: 'google',
          range,
          limit,
          offset,
          latitude,
          longitude,
        },
      },
    }) as any,
  60000
);

export const searchLocations = async (q: string, options: PaginationOptions & LocationOptions) =>
  await cachedSearchLocations()(q, options);

/* External dependencies */
import React from 'react';
import get from 'lodash/get';
import Colors from 'src/colors';
import Icon, { Icons } from 'src/icon/Icon';

export type Step = {
  title?: string;
  content: React.ReactNode;
  allowJumpTo?: boolean;
};

type OwnProps = {
  steps: Step[];
  activeStepIndex: number;
  stepsHeader?: React.ReactNode;
  showHeader?: boolean;
  onStepChange?: (stepIndex: number) => void;
};

type Props = OwnProps;

export const StepperHeader: React.FC<Pick<Props, 'steps' | 'activeStepIndex' | 'onStepChange'>> = ({
  steps = [],
  activeStepIndex = 0,
  onStepChange,
}) => {
  const createJumpToStep = (stepIndex: number) => () => {
    onStepChange && onStepChange(stepIndex);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      {steps.map((step, i) => {
        const { allowJumpTo = false } = step;
        const isPastStep = i < activeStepIndex;
        const isFutureStep = i > activeStepIndex;
        const isActiveStep = i === activeStepIndex;

        return (
          <div key={i} className={`d-flex align-items-center${i < steps.length - 1 ? ' mr-3' : ''}`}>
            <button
              disabled={isFutureStep || !allowJumpTo}
              onClick={createJumpToStep(i)}
              className={`btn d-flex align-items-center mr-4`}
            >
              <div
                className={`d-flex justify-content-center align-items-center mr-2`}
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 10,
                  borderWidth: isFutureStep || isActiveStep ? 2 : 0,
                  borderColor: isActiveStep ? Colors.primary : Colors.white,
                  borderStyle: 'solid',
                  backgroundColor: isFutureStep || isActiveStep ? Colors.transparent : Colors.white,
                  // color: isActiveStep ? undefined : '#fff',
                }}
              >
                {Boolean(isPastStep) && (
                  <Icon name={Icons.check} className="pb-1" size={14} />
                )}
              </div>
              <h6
                className="d-none d-lg-block m-0 p-0"
                style={{
                  color: Boolean(isActiveStep) ? Colors.primary : Colors.white,
                  fontSize: 14,
                  verticalAlign: 'center',
                }}
              >
                {step.title}
              </h6>
            </button>
            {Boolean(i < steps.length - 1) && <Icon name={Icons.chevronRight} className="bm-Icon--white mr-2" size={22} />}
          </div>
        );
      })}
    </div>
  );
};

const Stepper: React.FC<Props> = ({ steps = [], activeStepIndex = 0, stepsHeader, showHeader = true }) => {
  const step = steps[activeStepIndex];

  return (
    <div className="d-flex flex-column justify-content-center">
      {Boolean(showHeader) && (
        <>
          {stepsHeader ? (
            stepsHeader
          ) : (
            <StepperHeader steps={steps} activeStepIndex={activeStepIndex} />
          )}
        </>
      )}
      {Boolean(step) && (
        <>
          {get(step, 'content')}
        </>
      )}
    </div>
  );
};

export default Stepper;

/* External dependencies */
import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { signOut } from 'aws-amplify/auth';
import get from 'lodash/get';
import moment from 'moment';

/* Internal dependencies */
import { ReactComponent as Logo } from '../assets/images/beatmatch-horizontal.svg';
import { updateCurrentUser, getCurrentUser, CurrentUserState, resetCurrentUser, getCurrentUserLoading } from '../store/ducks/currentUser';
import { ApplicationState } from '../store';
import { User as UserType } from '../types/User';
import './Navbar.scss';
import Button from '../button/Button';
import { listBeatmatchEvents, searchEvents } from 'src/api/events';
import AuthModalButton from 'src/authModal/AuthModalButton';
import UserDefaultAvatar from 'src/user/UserDefaultAvatar';
import Icon, { Icons } from 'src/icon/Icon';
import Colors from 'src/colors';
import User from 'src/user/User';
import { Persona } from 'src/home/Home';
import SearchModalButton from 'src/search/SearchModalButton';
import { PaginationOptions } from 'src/types/Pagination';
import Item from 'src/item/Item';
import JoinEventButton from 'src/events/JoinEventButton';
import { providerToProviderCode } from 'src/events/Events';
import FollowArtistButton from 'src/artist/FollowArtistButton';
import JoinCommunityButton from 'src/community/JoinCommunityButton';

type InjectedProps = {
  history: any;
  location: any;
};

type OwnProps = {
  showLogo?: boolean;
  alwaysFillNavbar?: boolean;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
  currentUserLoading: CurrentUserState['loading'];
};

type DispatchProps = {
  updateCurrentUser(user: UserType): void;
  resetCurrentUser(): void;
};

type Props = InjectedProps & OwnProps & StateProps & DispatchProps;

type State = {
  fillNavbar: boolean;
  drawerOpen: boolean;
  beatmatchEvents: any[];
  searchItems: any[];
};

class Navbar extends React.Component<Props, State> {
  state = {
    fillNavbar:
      (window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop) > 45
        ? true
        : false,
    drawerOpen: false,
    beatmatchEvents: [],
    searchItems: [],
  };

  async componentDidMount() {
    const { alwaysFillNavbar = true } = this.props;
    if (!alwaysFillNavbar) {
      if (typeof window !== 'undefined') {
        window.document.addEventListener('scroll', this.handleScroll);
      }
    }
    if (typeof window !== 'undefined') {
      const toggle = window.document.getElementById('toggle');
      const menu = window.document.querySelector('.offcanvas-collapse');
      if (toggle) {
        toggle.addEventListener('click', (e) => {
          if (menu) {
            menu.classList.toggle('open');
            this.setState(({ drawerOpen }) => ({ drawerOpen: !drawerOpen }));
          }
        });
      }
    }

    const { items: beatmatchEvents = [] } = await listBeatmatchEvents();
    this.setState({ beatmatchEvents });
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.document.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    if (typeof window !== 'undefined') {
      const scrollTop =
        window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

      if (scrollTop > 45) {
        this.setState({ fillNavbar: true });
      } else {
        this.setState({ fillNavbar: false });
      }
    }
  };

  handleSignOut = async () => {
    const { history, resetCurrentUser } = this.props;
    try {
      await signOut({ global: true });
      resetCurrentUser();
      // history.push('/');
    } catch (e) {}
  };

  setSearchItems = (searchItems: any[]) => { this.setState({ searchItems }); };

  handleInitialLoad = async () => {
    return { items: [] };
  };

  handleSearchQuery = async (q: string = '', options: PaginationOptions) => {
    return await searchEvents(q, options);
  };

  render() {
    const {
      showLogo = true, alwaysFillNavbar = true,
      currentUser, currentUserLoading,
      location, history,
    } = this.props;
    const { fillNavbar, drawerOpen, beatmatchEvents = [], searchItems = [] } = this.state;
    const [beatmatchEvent] = beatmatchEvents;

    const pathName = get(location, 'pathname');
    let persona = Persona.fans;
  
    if (pathName === `/${Persona.fans}`) {
      persona = Persona.fans;
    } else if (pathName === `/${Persona.creators}`) {
      persona = Persona.creators;
    }

    const isFansPage = persona === Persona.fans;
    const isCreatorsPage = persona === Persona.creators;

    const searchButton = (
      <li key="search" className="nav-item d-flex align-items-center">
        <SearchModalButton
          items={searchItems}
          setItems={this.setSearchItems}
          onInitialLoad={this.handleInitialLoad}
          onQueryUpdate={this.handleSearchQuery}
          onItemClick={(item) => {
            const provider = get(item, 'provider');
            const providerId = get(item, 'providerId');

            history.push(`/e/${providerToProviderCode[provider]}/${providerId}`);
          }}
          placeholder="Search upcoming events..."
          renderItem={({ item }) => {
            const itemType = get(item, 'type');
            let right;

            if (itemType === 'event') {
              right = (
                <JoinEventButton
                  event={item}
                  fontSize="0.95rem"
                  width={75}
                  showIcon={false}
                  inactiveBackgroundColor={Colors.white}
                  inactiveTextColor={Colors.secondaryButton}
                />
              );
            } else if (itemType === 'artist') {
              right = (
                <FollowArtistButton
                  artist={item}
                  fontSize="0.95rem"
                  width={75}
                  showIcon={false}
                  inactiveBackgroundColor={Colors.white}
                  inactiveTextColor={Colors.secondaryButton}
                />
              );
            } else if (itemType === 'community') {
              right = (
                <JoinCommunityButton
                  community={item}
                  fontSize="0.95rem"
                  width={75}
                  showIcon={false}
                  inactiveBackgroundColor={Colors.white}
                  inactiveTextColor={Colors.secondaryButton}
                />
              );
            }


            return (
              <Item
                item={item}
                imageSize={85}
                enableLink={false}
                wrap={false}
                right={right}
              />
            );
          }}
          render={(onClick) => (
            <button
              onClick={onClick}
              className={`btn btn-secondary bg-${fillNavbar ? 'dark' : 'white'} d-flex justify-content-center align-items-center`}
              style={{ maxHeight: 'none', maxWidth: 'none', height: 45, width: 45, aspectRatio: '1', borderRadius: 22.5 }}
            >
              <Icon name={Icons.search} className={fillNavbar ? 'bm-Icon--white' : 'bm-Icon--dark'} size={24} />
            </button>
          )}
        />
      </li>
    );

    return (
      <>
        {Boolean(beatmatchEvent) && location.pathname === '/' && (
          <a target="_blank" href={(beatmatchEvent as any).externalUrl} role="button" className="container-fluid bg-primary d-flex flex-row justify-content-center align-items-center p-3" style={{ width: '100%' }}>
            <h5 className="lead text-white text-center" style={{ margin: 0 }}>Don't miss <u>{(beatmatchEvent as any).name}</u> on {`${moment((beatmatchEvent as any).date).format('dddd')}, ${moment((beatmatchEvent as any).date).format('MMM')} ${moment((beatmatchEvent as any).date).format('Do')}`}. Get tickets here!</h5>
          </a>
        )}
        <nav
          className={`bm-navbar navbar navbar-expand-lg navbar-light sticky-top ${
            fillNavbar || alwaysFillNavbar || drawerOpen ? ' bm-navbar--fill' : ''
          }`}
        >
          <div className="bm-navbar__body container">
            <NavLink
              className="navbar-brand d-flex align-items-center flex-wrap"
              to={location && location.pathname.startsWith('/taste') ? '/taste' : '/'}
            >
              {showLogo && (
                <Logo
                  className="bm-navbar__logo mr-3"
                  fill={fillNavbar || alwaysFillNavbar || drawerOpen ? '#96f' : '#fff'}
                />
              )}
              {Boolean(location && (location?.pathname === '/') ||
                location?.pathname.startsWith('/fans') ||
                location?.pathname.startsWith('/creators')
              ) && (
                  <Button
                    id="persona-switcher"
                    variant="primary"
                    className="bm-navbar__persona btn btn-secondary align-items-center m-0 p-0 pl-3 pr-2 pt-1 pb-1 text-white"
                    role="button"
                    style={{ backgroundColor: Colors.transparent, borderWidth: 2, borderRadius: 100 }}
                    dropdownItems={[
                      <Dropdown.Item key="fans" className="d-flex align-items-center pt-3 pb-3" onClick={() => { history.push('/fans') }}>
                        <span>Fans</span>
                      </Dropdown.Item>,
                      <Dropdown.Item key="creators" className="d-flex align-items-center pt-3 pb-3" onClick={() => { history.push('/creators') }}>
                        <span>Creators</span>
                      </Dropdown.Item>,
                    ]}  
                  >
                    <span
                      className={`${Boolean(fillNavbar || alwaysFillNavbar || drawerOpen) ? 'text-dark' : 'text-white'} m-0 p-0`}
                      style={{ fontSize: 14 }}
                    >
                      {location?.pathname.startsWith('/creators') ? 'For creators' : 'For fans'}
                    </span>
                    <Icon name={Icons.chevronDown} className={`${Boolean(fillNavbar || alwaysFillNavbar || drawerOpen) ? '' : 'bm-Icon--white'} ml-1`} size={18} />
                  </Button>
              )}
              {location && location.pathname.startsWith('/taste') && (
                <div className="bm-navbar__tasteTest">
                  <div
                    className="mr-3"
                    style={{
                      borderLeft: '3px solid #fff',
                      height: 22.5,
                      borderColor: fillNavbar ? '#96f' : '#fff',
                    }}
                  />
                  <h2
                    style={{
                      color: fillNavbar ? '#96f' : '#fff',
                      marginBottom: 0,
                      paddingBottom: 0,
                      fontSize: '1.6rem',
                      lineHeight: '1.5rem',
                      marginTop: '0.25rem',
                    }}
                  >
                    Taste Test
                  </h2>
                </div>
              )}
            </NavLink>
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <button
              id="toggle"
              className="navbar-toggler p-0 border-0"
              type="button"
              data-toggle="offcanvas"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse offcanvas-collapse justify-content-end" id="navbarNavAltMarkup">
              <ul
                className={`navbar-nav bm-navbar__rightNav${
                  fillNavbar || alwaysFillNavbar || drawerOpen ? ' bm-navbar__rightNav--fill' : ''
                }`}
              >
                <li key="home" className="nav-item">
                  <NavLink
                    exact={true}
                    isActive={(_, location) => {
                      return Boolean(location && (location?.pathname === '/') ||
                        location?.pathname.startsWith('/fans') ||
                        location?.pathname.startsWith('/creators')
                      );
                    }}
                    activeClassName="nav-link bm-navbar__link bm-navbar__link--active"
                    className="nav-link bm-navbar__link"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    activeClassName="nav-link bm-navbar__link bm-navbar__link--active"
                    className="nav-link bm-navbar__link"
                    to="/taste"
                  >
                    Taste Test
                  </NavLink>
                </li> */}
                {/* <li className="nav-item"><NavLink exact={true} activeClassName="nav-link bm-navbar__link bm-navbar__link--active" className="nav-link bm-navbar__link" to="/sync">Sync Up</NavLink></li> */}
                <li key="explore" className="nav-item">
                  <NavLink
                    exact={true}
                    isActive={(_, location) => {
                      return Boolean(location && (location?.pathname.startsWith('/explore') ||
                        location?.pathname.startsWith('/events') || location?.pathname.startsWith('/e/') ||
                        location?.pathname.startsWith('/communities')) || location?.pathname.startsWith('/c/') ||
                        location?.pathname.startsWith('/artists') || location?.pathname.startsWith('/a/') ||
                        location?.pathname.startsWith('/venues') ||
                        location?.pathname.startsWith('/neighborhoods')
                      );
                    }}
                    activeClassName="nav-link bm-navbar__link bm-navbar__link--active"
                    className="nav-link bm-navbar__link"
                    to="/explore"
                  >
                    Explore
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    exact={true}
                    isActive={(_, location) => {
                      return Boolean(location && (location?.pathname.startsWith('/events')));
                    }}
                    activeClassName="nav-link bm-navbar__link bm-navbar__link--active"
                    className="nav-link bm-navbar__link"
                    to="/events"
                  >
                    Events
                  </NavLink>
                </li> */}
                {/* <li key="cities" className="nav-item">
                  <NavLink
                    exact={true}
                    isActive={(_, location) => Boolean(location && location?.pathname.startsWith('/cities'))}
                    activeClassName="nav-link bm-navbar__link bm-navbar__link--active"
                    className="nav-link bm-navbar__link"
                    to="/cities"
                  >
                    Our cities
                  </NavLink>
                </li> */}
                {Boolean(currentUser) && (
                  <li key="tickets" className="nav-item">
                    <NavLink
                      exact={true}
                      isActive={(_, location) => Boolean(location && location?.pathname.startsWith('/tickets'))}
                      activeClassName="nav-link bm-navbar__link bm-navbar__link--active"
                      className="nav-link bm-navbar__link"
                      to="/tickets"
                    >
                      My tickets
                    </NavLink>
                  </li>
                )}
                {/* <li className="nav-item">
                  <NavLink
                    exact={true}
                    activeClassName="nav-link bm-navbar__link bm-navbar__link--active"
                    className="nav-link bm-navbar__link"
                    to="/ambassadors/campus-tastemakers"
                  >
                    Ambassadors
                  </NavLink>
                </li> */}
                <li key="magazine" className="nav-item">
                  <NavLink
                    exact={true}
                    isActive={(_, location) => Boolean(location && location?.pathname.startsWith('/magazine') || location?.pathname.startsWith('/blog'))}
                    activeClassName="nav-link bm-navbar__link bm-navbar__link--active"
                    className="nav-link bm-navbar__link"
                    to="/magazine"
                  >
                    Magazine
                  </NavLink>
                </li>
                {!Boolean(currentUserLoading) && (
                  <>
                    {Boolean(currentUser) ? (
                      <>
                        <li key="create" className="nav-item">
                          <Button
                            variant="primary"
                            className="btn btn-primary d-flex align-items-center"
                            role="button"
                            style={{ borderRadius: 100 }}
                            dropdownItems={[
                              <Dropdown.Item key="events" className="d-flex align-items-center pt-3 pb-3" onClick={() => { history.push('/events/new') }}>
                                <Icon name={Icons.ticket} className="mr-3" size={22} />
                                <span>New event</span>
                              </Dropdown.Item>,
                              <Dropdown.Item key="communities" className="d-flex align-items-center pt-3 pb-3" onClick={() => { history.push('/communities/new') }}>
                                <Icon name={Icons.users} className="mr-3" size={22} />
                                <span>New community</span>
                              </Dropdown.Item>,
                            ]}
                          >
                            <Icon name={Icons.plus} className="bm-Icon--white mr-1" size={22} />
                            <span className="">Create</span>
                          </Button>
                        </li>
                        {searchButton}
                        <li key="me" className="nav-item d-flex align-items-center">
                          <Button
                            className="btn nav-link bm-navbar__link bm-navbar__link--active"
                            style={{ border: 'none', padding: 0, margin: 0 }}
                            dropdownItems={[
                              <Dropdown.Item disabled={true} key="currentUser" className="d-flex align-items-center" style={{ minWidth: 300 }}>
                                <User user={currentUser!} textSize="1.25rem" subtextSize="0.9rem" imageSize={65} />
                              </Dropdown.Item>,
                              <div className="pt-1 pb-2" style={{ borderBottom: '0.5px solid rgba(0,0,0,0.25)', width: '100%' }} />,
                              <Dropdown.Item
                                key="communities"
                                className="d-flex align-items-center pt-3 pb-3"
                                href="/me/communities"
                                aria-label="View your communities"
                                onClick={(e) => {
                                  if (e && e.preventDefault) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }
                                  history.push('/me/communities');
                                }}
                                as="a"
                                style={{ cursor: 'pointer' }}
                              >
                                <Icon name={Icons.users} className="mr-3" size={22} />
                                <span>My communities</span>
                              </Dropdown.Item>,
                              <Dropdown.Item
                                key="events"
                                className="d-flex align-items-center pt-3 pb-3"
                                href="/me/events"
                                aria-label="View your events"
                                onClick={(e) => {
                                  if (e && e.preventDefault) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }
                                  history.push('/me/events');
                                }}
                                as="a"
                                style={{ cursor: 'pointer' }}
                              >
                                <Icon name={Icons.ticket} className="mr-3" size={22} />
                                <span>My events</span>
                              </Dropdown.Item>,
                              <div className="pt-1 pb-1" style={{ borderBottom: '0.5px solid rgba(0,0,0,0.25)', width: '100%' }} />,
                              <Dropdown.Item key="signOut" className="d-flex align-items-center pt-3 pb-3" onClick={this.handleSignOut}>
                                <Icon name={Icons.signOut} className="mr-3" size={22} />
                                <span>Log out</span>
                              </Dropdown.Item>,
                            ]}
                          >
                            {Boolean(get(currentUser, 'images[0].url')) ? (
                              <img
                                alt={currentUser!.name}
                                className="img img-fluid rounded-circle mr-2"
                                src={get(currentUser, 'images[0].url')}
                                width={40}
                                height={40}
                                style={{ width: 40, height: 40, borderRadius: 20, objectFit: 'cover' }}
                              />
                            ) : (
                              <div className="mr-2">
                                <UserDefaultAvatar
                                  user={currentUser!}
                                  size={40}
                                  style={{
                                    height: 40,
                                    width: 40,
                                    borderRadius: 20,
                                    objectFit: 'cover',
                                  }}
                                />
                              </div>
                            )}
                            <div
                              className="bg-white d-flex justify-content-center align-items-center"
                              style={{
                                position: 'absolute', bottom: -2, right: -2,
                                height: 22, width: 22, aspectRatio: '1',
                                borderRadius: 11,
                                borderWidth: fillNavbar ? 1 : 2,
                                borderColor: Colors.background,
                                borderStyle: 'solid',
                              }}
                            >
                              <Icon name={Icons.chevronDown} className="pb-1" size={18} />
                            </div>
                          </Button>
                        </li>
                      </>
                    ) : (
                      <div className="d-flex align-items-center">
                        <div className="mr-4">
                          {searchButton}
                        </div>
                        {Boolean(isFansPage) ? (
                          <AuthModalButton
                            modalHeader="Register / Sign in for Beatmatch"
                            modalSubheader="Discover things to do in your city with the coolest people near you. Browse recommended parties, concerts, festivals, and more based on your music library."
                            onClick={() => {}}
                            variant={fillNavbar ? 'primary' : 'secondary'}
                            color={fillNavbar ? '#fff' : 'rgb(75,75,75)'}
                          >
                            <h6 className="text-bold m-0 p-0" style={{ fontSize: 18 }}>Sign up</h6>
                          </AuthModalButton>
                        ) : (
                          <Link
                            to="/events/new"
                            className={`btn btn-${fillNavbar ? 'primary' : 'secondary'}`}
                            onClick={() => {
                              if (Boolean(isFansPage)) {
                                history.push('/explore');
                              } else if (isCreatorsPage) {
                                history.push('/events/new');
                              }
                            }}
                            style={{
                              color: fillNavbar ? '#fff' : 'rgb(75,75,75)',
                              borderRadius: 100,
                            }}
                          >
                            <h6 className="text-bold m-0 p-0" style={{ fontSize: 18 }}>Create event</h6>
                          </Link>
                        )}
                      </div>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
  currentUserLoading: getCurrentUserLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentUser: (user: UserType) => {
    dispatch(updateCurrentUser(user));
  },
  resetCurrentUser: () => {
    dispatch(resetCurrentUser());
  },
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(Navbar));

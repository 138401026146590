/* External dependencies */
import { combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator, generateReducer } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum BrowseActionType {
  SET_BROWSE_COLLECTIONS = 'reducer/browse/SET_BROWSE_COLLECTIONS',
  SET_BROWSE_LOADING = 'reducer/browse/SET_BROWSE_LOADING',
}

export type BrowseState = {
  collections: any[];
  loading: boolean;
};

export const INITIAL_STATE = {
  collections: [],
  loading: true,
};

/* Selectors */
export const getBrowseCollections = (state: ApplicationState) => state.browse.collections;
export const getBrowseLoading = (state: ApplicationState) => state.browse.loading;

/* Action Creator */
export const setBrowseCollections = generateActionCreator<BrowseActionType.SET_BROWSE_COLLECTIONS, BrowseState['collections']>(
  BrowseActionType.SET_BROWSE_COLLECTIONS
);

export const setBrowseLoading = generateActionCreator<BrowseActionType.SET_BROWSE_LOADING, BrowseState['loading']>(
  BrowseActionType.SET_BROWSE_LOADING
);

/* Reducers */
const collectionsReducer = generateReducer<BrowseActionType.SET_BROWSE_COLLECTIONS, BrowseState['collections']>(
  BrowseActionType.SET_BROWSE_COLLECTIONS,
  INITIAL_STATE.collections
);

const loadingReducer = generateReducer<BrowseActionType.SET_BROWSE_LOADING, BrowseState['loading']>(
  BrowseActionType.SET_BROWSE_LOADING,
  INITIAL_STATE.loading
);

const browseReducer = combineReducers({
  collections: collectionsReducer,
  loading: loadingReducer,
});

export default browseReducer;

/* External dependencies */
import React from 'react';

/* Internal dependencies */
import './MediaObject.scss';

type OwnProps = {
  text: React.ReactNode;
  textSize?: number | string;
  textColor?: 'light' | 'dark';
  subtext?: React.ReactNode;
  subtextSize?: number | string;
  showSubtext?: boolean;
  image?: React.ReactNode;
  imageType?: 'rounded' | 'circle' | 'square';
  imageSize?: number | string;
  imageMargin?: string | number;
  showImage?: boolean;
  right?: React.ReactNode;
  wrap?: boolean;
  className?: string;
  style?: any;
};

export type Props = OwnProps;

export const DEFAULT_IMAGE_SIZE = 75;

const MediaObject: React.FunctionComponent<Props> = ({
  text,
  subtext,
  textColor,
  textSize,
  subtextSize,
  showSubtext = true,
  image,
  imageType = 'rounded',
  imageSize = DEFAULT_IMAGE_SIZE,
  imageMargin,
  showImage = true,
  right,
  wrap = true,
  className = '',
  style,
}) => {
  const imageStyles: any = {
    height: imageSize,
    width: imageSize,
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: imageType === 'rounded' ? '0.5rem' : imageType === 'square' ? 0 : '50%',
  };

  return (
    <div className={`bm-MediaObject${wrap ? ' flex-wrap' : ''} ${className}`} style={style}>
      {Boolean(showImage) && (
        <div className="bm-MediaObject__imageContainer" style={{ marginRight: Boolean(imageMargin) ? imageMargin : undefined }}>
          {typeof image === 'string' ? (
            <img alt={typeof text === 'string' ? text : typeof subtext === 'string' ? subtext : ''} src={image} style={imageStyles} />
          ) : Boolean(image) ? (
            <>{image}</>
          ) : <div style={{ height: imageSize }} />}
        </div>
      )}
      <div className="bm-MediaObject__textContainer d-flex flex-column justify-content-center" style={{ width: '100%' }}>
        {typeof text === 'string' ? (
          <h6
            className={`bm-MediaObject_text d-flex flex-wrap ${Boolean(subtext) ? 'mb-1' : ''} m-0 p-0 ${textColor === 'light' ? 'text-light' : 'text-dark'}`}
            style={{ fontSize: textSize }}
          >
            {text}
          </h6>
        ) : text}
        {Boolean(showSubtext && subtext) && (
          <>
            {typeof subtext === 'string' ? (
              <h6
                className="bm-MediaObject_text bm-MediaObject__subtext d-flex flex-wrap m-0 p-0"
                style={{ fontSize: subtextSize }}
              >
                {subtext}
              </h6>
            ) : (
              subtext
            )}
          </>
        )}
      </div>
      {Boolean(right) && (
        <>{right}</>
      )}
    </div>
  );
};

export default MediaObject;

/* Internal dependencies */
import API from '.';

export const addFacebookCredentialForUser = async (userId: string, { accessToken, providerId }: any) =>
  await API.post({
    path: `/users/${userId}/credentials/facebook`,
    options: {
      body: {
        accessToken,
        providerId,
      },
    },
  });

/* External dependencies */
import { Action } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';
import { User } from '../../types/User';

enum CurrentUserActionType {
  UPDATE_CURRENT_USER = 'reducer/currentUser/UPDATE_CURRENT_USER',
  RESET_CURRENT_USER = 'reducer/currentUser/RESET_CURRENT_USER',
  UPDATE_CURRENT_USER_LOADING = 'reducer/currentUser/UPDATE_CURRENT_USER_LOADING',
}

export type CurrentUserState = {
  user: User | null;
  loading?: boolean | null;
};

/* Selectors */
export const getCurrentUser = (state: ApplicationState) => state.currentUser.user;
export const getCurrentUserLoading = (state: ApplicationState) => state.currentUser.loading;

/* Action creators */
export const updateCurrentUser = generateActionCreator<
  CurrentUserActionType.UPDATE_CURRENT_USER,
  CurrentUserState['user']
>(CurrentUserActionType.UPDATE_CURRENT_USER);

export const updateCurrentUserLoading = generateActionCreator<
  CurrentUserActionType.UPDATE_CURRENT_USER_LOADING,
  CurrentUserState['loading']
>(CurrentUserActionType.UPDATE_CURRENT_USER_LOADING);

export const resetCurrentUser = generateActionCreator<
  CurrentUserActionType.RESET_CURRENT_USER,
  CurrentUserState['user']
>(CurrentUserActionType.RESET_CURRENT_USER);

export const INITIAL_STATE: CurrentUserState = {
  user: null,
  loading: true,
};

/* Reducer */
const currentUserReducer = (
  state: CurrentUserState = INITIAL_STATE as any,
  action: Action & { payload: any }
): CurrentUserState => {
  const { payload } = action;
  if (action.type === CurrentUserActionType.UPDATE_CURRENT_USER) {
    return {
      ...state,
      user: {
        ...state.user,
        ...payload,
      },
    };
  }

  if (action.type === CurrentUserActionType.UPDATE_CURRENT_USER_LOADING) {
    return {
      ...state,
      loading: payload,
    };
  }

  if (action.type === CurrentUserActionType.RESET_CURRENT_USER) {
    return { ...state, user: null, loading: false };
  }
  return state;
};

export default currentUserReducer;

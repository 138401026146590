/* External dependencies */
import { Action, combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum VenuesActionType {
  SET_VENUES = 'reducer/venues/SET_VENUES',
  UPDATE_VENUE = 'reducer/venues/UPDATE_VENUE',
  UPDATE_VENUES = 'reducer/venues/UPDATE_VENUES',
}

export type VenuesState = {
  items: { [venueId: string]: any };
};

export const INITIAL_STATE = {
  items: {},
};

/* Selectors */
export const getVenues = (state: ApplicationState) => Object.values(state.venues.items);
export const getVenue = (state: ApplicationState, venueId: string) => state.venues.items[venueId];

/* Action Creator */
export const setVenues = generateActionCreator<VenuesActionType.SET_VENUES, VenuesState['items']>(
  VenuesActionType.SET_VENUES
);

export const updateVenue = generateActionCreator<VenuesActionType.UPDATE_VENUE, any>(
  VenuesActionType.UPDATE_VENUE
);

export const updateVenues = generateActionCreator<VenuesActionType.UPDATE_VENUES, any[]>(
  VenuesActionType.UPDATE_VENUES
);

/* Reducers */
const itemsReducerWrapper = (
  state: VenuesState['items'] = INITIAL_STATE.items,
  action: Action & { payload: any }
): VenuesState['items'] => {
  const { payload } = action;
  if (action.type === VenuesActionType.SET_VENUES) {
    return payload.reduce((acc: any, curr: any) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === VenuesActionType.UPDATE_VENUE) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...state[id],
        ...payload,
      },
    };
  } else if (action.type === VenuesActionType.UPDATE_VENUES) {
    const venues = payload;

    return {
      ...state,
      ...venues.reduce((acc: any, curr: any) => {
        const { id } = curr;

        return {
          ...acc,
          [id]: {
            ...state[id],
            ...curr,
          },
        };
      }, {}),
    };
  }

  return state;
};

const venuesReducer = combineReducers({
  items: itemsReducerWrapper,
});

export default venuesReducer;

/* External dependencies */
import React, { Ref } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

/* External dependencies */
import { updateTrack, getTrack } from '../store/ducks/player';
import { ApplicationState } from 'src/store';

export type RenderParams = State & {
  play(): void;
  pause(): void;
  playbackStatus: any;
};

type OwnProps = {
  preload?: boolean;
  track: any;
  render(params: RenderParams): React.ReactNode;
};

type StateProps = {
  currentTrack: any;
};

type DispatchProps = {
  updateTrack(track: any | null): void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  loading: boolean;
  playing: boolean;
  progress: number;
  currentTime: number;
};

const INITIAL_STATE = {
  loading: true,
  playing: false,
  progress: 0,
  currentTime: 0,
};

class Player extends React.Component<Props, State> {
  state = INITIAL_STATE;

  audio: HTMLAudioElement | undefined;
  audioRef: Ref<HTMLAudioElement>;
  canPlayIntervalId: number | undefined;

  constructor(props: Props) {
    super(props);
    this.audioRef = React.createRef<HTMLAudioElement>();
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (this.audioRef && (this.audioRef as any).current) {
        this.audio = (this.audioRef as any).current;
        if (this.audio) {
          this.audio.addEventListener('progress', this._loadeddata);
          this.audio.addEventListener('play', this._play);
          this.audio.addEventListener('pause', this._pause);
          this.audio.addEventListener('ended', this._ended);
          this.audio.addEventListener('timeupdate', this._updateStatus);
        }
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      if (this.audio) {
        this.audio.removeEventListener('progress', this._loadeddata);
        this.audio.removeEventListener('play', this._play);
        this.audio.removeEventListener('pause', this._pause);
        this.audio.removeEventListener('ended', this._ended);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { track = {}, currentTrack = {} } = this.props;
    if (prevProps.track.previewUrl !== track.previewUrl) {
      this.setState(INITIAL_STATE);
    }

    if ((track && track.id) !== (currentTrack && currentTrack.id) && this.state.playing) {
      this.pause();
    }
  }

  initialize = () => {};

  load = () => {
    this.audio && this.audio.load();
  };

  _loadeddata = () => {
    this.setState({ loading: false });
  };

  _pause = () => {
    this.setState({ playing: false });
  };

  _ended = () => {
    this.setState({ playing: false });
  };

  _play = () => {
    this.setState({ playing: true });
  };

  _updateStatus = () => {
    if (this.audio) {
      let duration = this.audio.duration;
      if (duration > 0) {
        this.setState({
          progress: this.audio.currentTime / duration,
          currentTime: this.audio.currentTime,
        });
      }
    }
  };

  play = async () => {
    if (typeof window !== 'undefined') {
      const { track, updateTrack } = this.props;
      if (this.audio) {
        const { playing, loading } = this.state;
        if (loading) {
          if (!this.canPlayIntervalId) {
            this.canPlayIntervalId = window.setInterval(this._play, 300);
          }
        } else if (!playing) {
          this.audio.play();
          updateTrack(track);
          window.clearInterval(this.canPlayIntervalId);
          this.canPlayIntervalId = undefined;
        }
      } else {
        console.error('Audio object not initialized yet.');
      }
    }
  };

  pause = () => {
    this.audio && this.audio.pause();
  };

  render() {
    const { preload = true, track, render } = this.props;
    const { loading, playing, progress } = this.state;

    return (
      <>
        <audio src={track.previewUrl} ref={this.audioRef} preload={`${preload}`} />
        {render({
          loading,
          playing,
          progress,
          play: this.play,
          pause: this.pause,
        } as any)}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentTrack: getTrack(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateTrack: (track: any) => {
    dispatch(updateTrack(track));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);

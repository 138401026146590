/* External dependencies */
import { Action, combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';
import Collection from 'src/types/Collection';

enum CollectionsActionType {
  SET_COLLECTIONS = 'reducer/collections/SET_COLLECTIONS',
  UPDATE_COLLECTION = 'reducer/collections/UPDATE_COLLECTION',
  UPDATE_COLLECTIONS = 'reducer/collections/UPDATE_COLLECTIONS',
}

export type CollectionsState = {
  items: { [collectionId: string]: any };
};

export const INITIAL_STATE = {
  items: {},
};

/* Selectors */
export const getCollections = (state: ApplicationState) => Object.values(state.collections.items);
export const getCollection = (state: ApplicationState, collectionId: string) => state.collections.items[collectionId];

/* Action Creator */
export const setCollections = generateActionCreator<CollectionsActionType.SET_COLLECTIONS, CollectionsState['items']>(
  CollectionsActionType.SET_COLLECTIONS
);

export const updateCollection = generateActionCreator<CollectionsActionType.UPDATE_COLLECTION, any>(
  CollectionsActionType.UPDATE_COLLECTION
);

export const updateCollections = generateActionCreator<CollectionsActionType.UPDATE_COLLECTIONS, any[]>(
  CollectionsActionType.UPDATE_COLLECTIONS
);

/* Reducers */
const itemsReducerWrapper = (
  state: CollectionsState['items'] = INITIAL_STATE.items,
  action: Action & { payload: any }
): CollectionsState['items'] => {
  const { payload } = action;
  if (action.type === CollectionsActionType.SET_COLLECTIONS) {
    return payload.reduce((acc: CollectionsState['items'] , curr: Collection) => ({
      ...acc,
      [curr.id]: {
        ...state[curr.id],
        ...curr,
      },
    }), {});
  } else if (action.type === CollectionsActionType.UPDATE_COLLECTION) {
    const { id } = payload;

    return {
      ...state,
      [id]: {
        ...state[id],
        ...payload,
      },
    };
  } else if (action.type === CollectionsActionType.UPDATE_COLLECTIONS) {
    const collections = payload;

    return {
      ...state,
      ...collections.reduce((acc: CollectionsState['items'] , curr: Collection) => {
        const { id } = curr;

        return {
          ...acc,
          [id]: {
            ...state[id],
            ...curr,
          },
        };
      }, {}),
    };
  }

  return state;
};

const collectionsReducer = combineReducers({
  items: itemsReducerWrapper,
});

export default collectionsReducer;

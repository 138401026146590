/* External dependencies */
import { createTransform } from 'redux-persist';
import get from 'lodash/get';
import moment from 'moment'; // or any date library you prefer

const transform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState: any) => {
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState: any) => {
    // You can also filter on rehydration if needed
    return {
      ...outboundState,
      collections: [
        ...get(outboundState, 'collections', []).map((collection: any) => {
          const items = get(collection, 'items', []);
          const itemsType = get(items, '[0.type');

          if (itemsType === 'event' || itemsType === 'activity') {
            return {
              ...collection,
              items: get(collection, 'items', []).filter((item: any) => moment(get(item, 'endDate', get(item, 'date'))).isAfter(moment()))
            }
          }

          return collection;
        }),
      ],
    };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['browse'] }
);

export default transform;

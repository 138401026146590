/* External dependencies */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import get from 'lodash/get';

/* Internal dependencies */
import MediaObject, { DEFAULT_IMAGE_SIZE } from '../mediaObject/MediaObject';
import Player from '../player/Player';
import Spinner from '../spinner/Spinner';
import { providerToProviderCode } from 'src/artist/ArtistDetail';
import { slugify } from 'src/store/helpers/users';
import Colors from 'src/colors';
import { addNotification } from 'src/store/ducks/notifications';
import { Notification } from 'src/types/Notification';

type OwnProps = {
  track: any;
  textColor?: 'light' | 'dark';
  imageSize?: number;
  right?: React.ReactNode;
  wrap?: boolean;
  enableArtistLink?: boolean;
};

type DispatchProps = {
  addNotification(notification: Omit<Notification, 'id'>): void;
};

type Props = OwnProps & DispatchProps;

const MAX_IMAGE_SIZE = 105;

const Track: React.FunctionComponent<Props> = ({
  track, textColor, imageSize = DEFAULT_IMAGE_SIZE,
  right, wrap, enableArtistLink = true,
  addNotification,
}) => {
  const { artists = [] } = track;
  const [artist] = artists;
  const provider = get(artist, 'provider', '');
  const providerId = get(artist, 'providerId', '');
  const artistName = get(artist, 'name', '');
  const artistSlug = slugify(artistName);

  if (imageSize > MAX_IMAGE_SIZE) {
    imageSize = MAX_IMAGE_SIZE;
  }

  return (
    <MediaObject
      text={track.name}
      subtext={Boolean(enableArtistLink) ? (
        <Link
          to={`/artists/${providerToProviderCode[provider]}/${providerId}/${artistSlug}`}
        >
          <h6 className="bm-MediaObject_text bm-MediaObject__subtext d-flex flex-wrap m-0 p-0">{artistName}</h6>
        </Link>
      ) : <h6 className="bm-MediaObject_text bm-MediaObject__subtext d-flex flex-wrap m-0 p-0">{artistName}</h6>}
      textColor={textColor}
      imageType="rounded"
      imageSize={imageSize}
      image={
        <div style={{ position: 'relative' }}>
          <img
            alt={track.name}
            src={track.images[0].url}
            style={{
              height: imageSize,
              width: imageSize,
              overflow: 'hidden',
              objectFit: 'cover',
              borderRadius: 10,
            }}
          />
          <Player
            track={track}
            render={({ play, pause, loading, playing, progress }) => (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgressbarWithChildren
                  value={Math.floor(progress * 100)}
                  maxValue={100}
                  strokeWidth={12}
                  styles={{
                    root: {
                      width: imageSize / 1.293,
                      height: imageSize / 1.293,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 0,
                      margin: 0,
                    },
                    path: {
                      stroke: Colors.primary,
                    },
                  }}
                >
                  <button
                    className="btn"
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      if (track.previewUrl) {
                        playing && pause();
                        !playing && play();
                      } else {
                        addNotification({
                          title: 'Can\'t play song',
                          message: 'This song cannot be played in your region.',
                          variant: 'danger',
                        } as Notification);
                      }
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: imageSize / 1.45,
                      width: imageSize / 1.45,
                      borderRadius: (imageSize / 1.45) / 2,
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                      padding: 0,
                      margin: 0,
                      maxHeight: imageSize / 1.45,
                    }}
                  >
                    {!loading && playing && (
                      <i
                        className="material-icons"
                        style={{
                          backgroundColor: '#96f',
                          fontSize: imageSize / 1.25,
                          color: '#fff',
                          opacity: 0.95,
                        }}
                      >
                        pause_circle_filled
                      </i>
                    )}
                    {!loading && !playing && (
                      <i
                        className="material-icons"
                        style={{
                          backgroundColor: '#96f',
                          fontSize: imageSize / 1.25,
                          color: '#fff',
                          opacity: 0.95,
                        }}
                      >
                        play_circle_filled
                      </i>
                    )}
                    {loading && <Spinner />}
                  </button>
                </CircularProgressbarWithChildren>
              </div>
            )}
          />
        </div>
      }
      right={right}
      wrap={wrap}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNotification: (notification: Omit<Notification, 'id'>) => { dispatch(addNotification(notification)); },
});

export default connect(null, mapDispatchToProps)(Track);

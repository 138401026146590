/* External dependencies */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Widget } from '@typeform/embed-react'

/* Internal dependencies */
import './VerificationApplication.scss';

const VerificationApplication: React.FC = () => (
  <>
    <Helmet>
      <title>Beamatch | Apply for Beatmatch Verification</title>
    </Helmet>
    <section className="bm-VerificationApplication">
      <div>
        <Widget
          id="fpOHR6bj"
          transitiveSearchParams={['user_id']}
          style={{ height: '100vh', width: '100vw' }}
        />
      </div>
    </section>
  </>
);

export default VerificationApplication;
/* Internal dependencies */
import browseTransform from './browse';
import compressTransform from './compress';


const transforms: any = [
  browseTransform,
  compressTransform,
];


export default transforms;
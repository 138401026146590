/* External dependencies */
import numbro from 'numbro';

export const formatNumber = (value: number): string => {
  // Define breakpoints for different formatting
  const thousand = 1000;
  const million = 1000000;
  const billion = 1000000000;

  // Determine the scale for rounding
  let scale = 1;
  if (value >= thousand && value < million) {
    scale = thousand;
  } else if (value >= million && value < billion) {
    scale = million;
  } else if (value >= billion) {
    scale = billion;
  }

  // Adjust the value for formatting to prevent undesired rounding
  const adjustedValue = Math.floor(value / scale * 10) / 10 * scale;

  // Formatting options
  const formatOptions = {
    average: true,
    mantissa: value < thousand ? 0 : 1, // No decimal places for less than 1000, 1 decimal place for thousands and beyond
    trimMantissa: true, // Trim unnecessary "0" from mantissa
  };

  return numbro(adjustedValue).format(formatOptions);
};

export const formatPrice = (value: number) => {
  if (Number.isInteger(value)) {
    // If the value is an integer, return the formatted price without decimals
    return numbro(value).formatCurrency({
      thousandSeparated: true,
      mantissa: 0, // No decimal places
    });
  } else {
    // If the value has decimals, format it with two decimal places
    return numbro(value).formatCurrency({
      thousandSeparated: true,
      mantissa: 2, // Two decimal places
    });
  }
};

export const isValidNumber = (value: number) => {
  return typeof value === 'number' && !isNaN(value);
};
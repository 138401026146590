/* External dependencies */
import { Action, combineReducers } from 'redux';

/* Internal dependencies */
import { generateActionCreator, generateReducer } from '../helpers/duckGenerators';
import { ApplicationState } from '..';

enum LikesActionType {
  UPDATE_LIKES = 'reducer/likes/UPDATE_LIKES',
  UPDATE_LIKE = 'reducer/likes/UPDATE_LIKE',
  REMOVE_LIKE = 'reducer/likes/REMOVE_LIKE',
  RESET_LIKES = 'reducer/likes/RESET_LIKES',
  SET_LIKES_LOADING = 'reducer/likes/SET_LIKES_LOADING',
  SET_LIKES_REMAINING = 'reducer/likes/SET_LIKES_REMAINING',
  SET_LIKES_SENT = 'reducer/likes/SET_LIKES_SENT',
}

export type LikesState = {
  items: any[];
  loading: boolean;
  likesRemaining: number;
  likesSent: any[];
};

/* Selectors */
export const getLikes = (state: ApplicationState) => state.likes.items;
export const getLikesLoading = (state: ApplicationState) => state.likes.loading;
export const getLikesRemaining = (state: ApplicationState) => state.likes.likesRemaining;
export const getLikesSent = (state: ApplicationState) => state.likes.likesSent;

/* Action creators */
export const updateLikes = (payload: any[]) => ({ type: LikesActionType.UPDATE_LIKES, payload });

export const updateLike = (payload: any) => ({ type: LikesActionType.UPDATE_LIKE, payload });

export const setLikesLoading = generateActionCreator<LikesActionType.SET_LIKES_LOADING, LikesState['loading']>(
  LikesActionType.SET_LIKES_LOADING
);

export const removeLike = generateActionCreator<LikesActionType.REMOVE_LIKE, string>(LikesActionType.REMOVE_LIKE);

export const resetLikes = generateActionCreator<LikesActionType.RESET_LIKES, LikesState>(LikesActionType.RESET_LIKES);
export const setLikesRemaining = generateActionCreator<
  LikesActionType.SET_LIKES_REMAINING,
  LikesState['likesRemaining']
>(LikesActionType.SET_LIKES_REMAINING);
export const setLikesSent = generateActionCreator<LikesActionType.SET_LIKES_SENT, LikesState['likesSent']>(
  LikesActionType.SET_LIKES_SENT
);

export const INITIAL_STATE: LikesState = {
  items: [],
  loading: true,
  likesRemaining: 10,
  likesSent: [],
};

/* Reducer */
const itemsReducer = (
  state: LikesState['items'] = INITIAL_STATE.items,
  action: Action & { payload: any }
): LikesState['items'] => {
  const { payload } = action;
  if (action.type === LikesActionType.UPDATE_LIKES) {
    return [...payload];
  }

  if (action.type === LikesActionType.UPDATE_LIKE) {
    const itemIndex = state.findIndex(({ id }) => id === payload.id);
    if (itemIndex !== -1) {
      return [...state.slice(0, itemIndex), payload, ...state.slice(itemIndex + 1)];
    }
    return [...state];
  }

  if (action.type === LikesActionType.REMOVE_LIKE) {
    const index = state.findIndex(({ id }) => id === payload);
    if (index === -1) return state;

    return [...state.slice(0, index), ...state.slice(index + 1)];
  }

  if (action.type === LikesActionType.RESET_LIKES) {
    return [...INITIAL_STATE.items];
  }

  return state;
};

const loadingReducer = generateReducer<LikesActionType.SET_LIKES_LOADING, LikesState['loading']>(
  LikesActionType.SET_LIKES_LOADING,
  INITIAL_STATE.loading
);

const likesSentReducer = generateReducer<LikesActionType.SET_LIKES_SENT, LikesState['likesSent']>(
  LikesActionType.SET_LIKES_SENT,
  INITIAL_STATE.likesSent
);

const likesRemainingReducer = generateReducer<LikesActionType.SET_LIKES_REMAINING, LikesState['likesRemaining']>(
  LikesActionType.SET_LIKES_REMAINING,
  INITIAL_STATE.likesRemaining
);

const likesReducer = combineReducers({
  items: itemsReducer,
  // offset: offsetReducer,
  loading: loadingReducer,
  likesSent: likesSentReducer,
  likesRemaining: likesRemainingReducer,
});

export default likesReducer;

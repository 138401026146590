/* External dependencies */
import React from 'react';
import { Cache } from 'aws-amplify/utils';
import {
  signInWithRedirect,
} from 'aws-amplify/auth';
import get from 'lodash/get';

/* Internal dependencies */
import Button from '../button/Button';
import { getUser, addImageForUser2, createUser } from '../api/users';
import { updateCurrentUser, getCurrentUser, CurrentUserState } from '../store/ducks/currentUser';
import { modelUser } from '../store/helpers/users';
import { User, ImageProvider } from '../types/User';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { addFacebookCredentialForUser } from '../api/credentials';
import { toDataURL } from 'src/helpers/images';
import Spinner from '../spinner/Spinner';
import { getCurrentUserInfo, getAuthenticatedUser } from 'src/auth';

type OwnProps = {
  onSuccess?: () => void;
};

type StateProps = {
  currentUser: CurrentUserState['user'];
};

type DispatchProps = {
  updateCurrentUser(user: User): void;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  loading: boolean;
};

class ConnectToFacebookButton extends React.Component<Props, State> {
  state = { loading: false };

  fbLogin = async (params: any): Promise<any> => {
    if (!(window as any).FB) {
      await (window as any).fbLoaded.promise;
    }
    return await new Promise((res) => {
      (window as any).FB.login(res, params);
    });
  };

  fbApi = async (params: any): Promise<any> => {
    if (!(window as any).FB) {
      await (window as any).fbLoaded.promise;
    }
    return await new Promise((res) => {
      (window as any).FB.api(params, res);
    });
  };

  loginToFacebook = async () => {
    const loginResponse = await this.fbLogin({
      scope: ['public_profile', 'email'].join(','),
    });
    const { authResponse } = loginResponse;
    console.log('authResponse', authResponse);
    if (authResponse && authResponse.accessToken) {
      const { accessToken: token, expiresIn } = authResponse;
      const expires_at = expiresIn! * 1000 + new Date().getTime();
      const {
        id: providerId,
        first_name,
        last_name,
        birthday,
        email,
        gender,
      } = await this.fbApi('/me?fields=id,name,first_name,last_name,email,birthday,picture,gender');
      const pictureResponse = await fetch(
        `https://graph.facebook.com/${providerId}/picture?redirect=false&height=500&width=500`
      );
      const { data } = await pictureResponse.json();
      const { url, height, width } = data;
      const imageDataUrl = await toDataURL(url);

      return {
        credentials: { token, expires_at, providerId },
        fbUser: {
          name: first_name,
          firstName: first_name,
          lastName: last_name,
          birthday,
          email,
          gender,
          picture: { url: imageDataUrl, height, width },
        },
      };
    } else {
      console.log('User cancelled login or did not fully authorize.');
    }
    return {};
  };

  handleFacebookSignIn = async () => {
    try {
      this.setState({ loading: true });
      const { onSuccess, updateCurrentUser } = this.props;
      const login: any = await this.loginToFacebook();
      const { credentials, fbUser } = login;

      if (credentials) {
        const { token, expires_at, providerId } = credentials;
        await signInWithRedirect({
          provider: 'Facebook',
          // credentials, fbUser
        });
        const [authUser, , authSession] = await Promise.all([
          getCurrentUserInfo(),
          Cache.setItem(`fbToken_${process.env.NODE_ENV}`, token),
          getAuthenticatedUser(),
        ]);

        const identityId = get(authSession, 'identityId');

        const userId = `user:${identityId}`;
        let user;
        try {
          user = await getUser(userId, { mini: true });
        } catch (e) {
          console.log('getUser error', e);
          user = await createUser({
            ...authUser.attributes,
            name: get(authUser, 'firstName'),
            email: fbUser.email,
            gender: fbUser.gender,
            birthday: fbUser.birthday,
            images: [],
          });
        }

        if (!user.images || !user.images.length) {
          const image = {
            height: fbUser.picture.height,
            width: fbUser.picture.width,
            url: fbUser.picture.url,
            provider: ImageProvider.facebook,
          };
          user = await addImageForUser2(user.id, image);
        }
        updateCurrentUser(modelUser(user));

        await addFacebookCredentialForUser(userId, {
          accessToken: token,
          expires_at,
          providerId,
        });
        onSuccess && onSuccess();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { currentUser } = this.props;
    const { loading } = this.state;

    if (loading) return <Spinner />;
    if (currentUser) return null;

    return (
      <Button
        className="text-white pl-2 pr-2 d-flex justify-content-center align-items-center"
        variant="secondary"
        onClick={this.handleFacebookSignIn}
        style={{ borderRadius: 30, fontWeight: 700, height: 45, width: '100%' }}
      >
        <i className="fa fa-facebook-square text-dark" style={{ fontSize: 24, marginRight: 10 }} />
        <h5 className="text-bold text-dark p-0 m-0" style={{ fontSize: '1.15rem' }}>Continue with Facebook</h5>
      </Button>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCurrentUser: (user: User) => {
    dispatch(updateCurrentUser(user));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectToFacebookButton);

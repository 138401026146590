/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { fetchAll } from 'src/common/helpers/fetchAll';
import { addNotification } from '../ducks/notifications';
import { put, select } from '../reduxStory';
import { getCurrentUser, updateCurrentUser } from '../ducks/currentUser';
import { updateEvent } from '../ducks/events';
import { addEventForUser, removeEventForUser, getEvent as getEventApi } from 'src/api/events';

export const addEvent = async (event: any) => {
  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  try {
    event.added = true;
    const users = get(event, 'users.items', []);
    
    put(
      updateCurrentUser({ // TODO: Update the event object in Redux state instead of current user
        ...currentUser,
        events: [...currentUser.events, event],
        // eventIds: currentUser.eventIds.add(event.id),
      })
    );
    put(updateEvent({
      ...event,
      users: {
        ...event.users,
        items: [
          currentUser,
          ...users,
        ],
        total: get(event, 'users.total', get(event, 'users.items.length', 0)) + 1,
      },
      added: true,
    }));
    return await addEventForUser(currentUser.id, event);
  } catch (e) {
    console.log('addEvent error: ', e);
    event.added = false;
    put(updateCurrentUser({ ...currentUser }));
    put(updateEvent(event));
  }
};

export const removeEvent = async (event: any) => {
  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  const { id } = event;
  try {
    const eventIndex = currentUser.events.findIndex(({ id: eventId }) => id === eventId);
    const users = get(event, 'users.items', []);
    const userIndex = users.findIndex((user: any) => user.id === currentUser.id);
    event.added = false;
    

    put(
      updateCurrentUser({
        ...currentUser,
        events:
          eventIndex !== -1
            ? [...currentUser.events.slice(0, eventIndex), ...currentUser.events.slice(eventIndex + 1)]
            : undefined,
      })
    );
    put(updateEvent({
      ...event,
      users: {
        ...event.users,
        items: userIndex !== -1 ? [
          ...users.slice(0, userIndex),
          ...users.slice(userIndex + 1),
        ] : users,
        total: get(event, 'users.total', get(event, 'users.items.length', 0)) - 1,
      },
      added: false,
    }));
    return await removeEventForUser(id, currentUser.id);
  } catch {
    event.added = true;
    put(
      updateCurrentUser({
        ...currentUser,
      })
    );
    put(updateEvent(event));
  }
};

export const toggleEvent = async (event: any) => {
  const { id, date, added } = event;

  if (Boolean(added)) {
    put(
      addNotification({
        title: 'Event removed',
        message: 'You unjoined the event.',
        items: [event],
        variant: 'primary',
      })
    );
    console.log('event to remove', event);
    console.log('event ID to remove', id);
    return await fetchAll([
      removeEvent(event),
      // Analytics.track(AnalyticsEventType.eventRemoved, { eventId: id, date }),
    ]);
  } else {
    put(
      addNotification({
        title: 'Event added',
        message: 'You joined the event.',
        items: [event],
        variant: 'primary',
      })
    );
    console.log('event to add', event);
    console.log('event ID to add', id);
    return await fetchAll([
      addEvent(event),
      // Analytics.track(AnalyticsEventType.eventAdded, { eventId: id, date }),
      // Analytics.track(AnalyticsEventType.eventSaved, { eventId: id, date }),
    ]);
  }
};

export const getEvent = async (eventId: string, options: { cache: boolean; } = { cache: true }) => {
  const event = await getEventApi(eventId, options);
  // const users = get(event, 'users.items', []).map(modelUser);

  // put(updateUsers(users));
  put(updateEvent(event));

  return event;
};
/* External dependencies */
import React from 'react';
import { connect } from 'react-redux';

/* Internal dependencies */
import { removeNotification } from 'src/store/ducks/notifications';
import { Notification as NotificationType } from 'src/types/Notification';
import './Notification.scss';
import { Dispatch } from 'redux';
import { Alert } from 'react-bootstrap';

type OwnProps = {
  notification: NotificationType;
};

type DispatchProps = {
  removeNotification(id: string): void;
};

type Props = OwnProps & DispatchProps;

type State = { hidden: boolean };

class Notification extends React.Component<Props, State> {
  timeout: number | undefined;
  state = { hidden: true };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.timeout = window.setTimeout(() => {
        this.removeNotification();
      }, 8000);
      window.setTimeout(() => {
        this.setState({ hidden: false });
      }, 200);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  removeNotification = () => {
    if (typeof window !== 'undefined') {
      const { notification, removeNotification } = this.props;
      this.setState({ hidden: true });
      window.setTimeout(() => {
        removeNotification(notification.id);
      }, 300);
    }
  };

  render() {
    const { notification } = this.props;
    const { hidden } = this.state;
    const { variant = 'primary' } = notification;

    if (true) {
      return (
        <Alert
          key={new Date().getTime()}
          variant={variant}
          className={`mt-4 shadow${
            !hidden ? ' bm-Notification--visible' : ' bm-Notification'
          }`}
          onClick={this.removeNotification}
          style={{
            borderRadius: 10,
            minWidth: '25%',
            maxWidth: 375,
            maxHeight: 1000,
          }}
        >
          <Alert.Heading className="text-bold" style={{ fontSize: 18 }}>
            {notification.title}
            {/* <button className="btn p-0" onClick={this.removeNotification}>
              <i className={`fa fa-close text-${variant === 'danger' ? 'white' : 'dark'}`} />
            </button> */}
          </Alert.Heading>
          <p className="m-0 p-0" style={{ maxWidth: '100%', whiteSpace: 'initial' }}>{notification.message}</p>
        </Alert>
      );
    }

    return (
      <button
        onClick={this.removeNotification}
        className={`btn btn-${variant} d-flex flex-column align-items-start rounded pl-3 pt-2 pb-2 pr-2 flex-wrap shadow${
          !hidden ? ' bm-Notification--visible' : ' bm-Notification'
        }`}
        style={{
          overflow: 'hidden',
          minWidth: '25%',
          maxWidth: 375,
          maxHeight: 1000,
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-1" style={{ width: '100%' }}>
          <h6
            className={`text-${variant === 'primary' || variant === 'danger' ? 'white' : 'dark'} m-0`}
            style={{ fontWeight: 600 }}
          >
            {notification.title}
          </h6>
          <button className="btn p-0" onClick={this.removeNotification}>
            <i className={`fa fa-close text-${variant === 'primary' || variant === 'danger' ? 'white' : 'dark'}`} />
          </button>
        </div>
        <p
          className={`d-flex flex-wrap text-left text-${
            variant === 'primary' || variant === 'danger' ? 'white' : 'dark'
          } pb-1 mb-0`}
          style={{ maxWidth: '100%', whiteSpace: 'initial' }}
        >
          {notification.message}
        </p>
      </button>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeNotification: (id: string) => {
    dispatch(removeNotification(id));
  },
});

export default connect(null, mapDispatchToProps)(Notification);

/* External dependencies */
import get from 'lodash/get';

/* Internal dependencies */
import { addArtistForUser, removeArtistForUser, getArtist as getArtistApi } from '../../api/artists';
import { select, put } from '../reduxStory';
import { getCurrentUser, updateCurrentUser } from '../ducks/currentUser';
import { addNotification } from '../../store/ducks/notifications';
import { updateArtist } from '../ducks/artists';
import { cleanObject } from 'src/common/cleanObject';

export const addArtist = async (artist: any) => {
  const currentUser = select(getCurrentUser);

  if (!currentUser) return;
  
  try {
    artist.added = true;
    put(updateArtist(artist));
    put(
      updateCurrentUser({
        ...currentUser,
        artists: [...currentUser.artists, artist],
      })
    );
    return await addArtistForUser(currentUser.id, artist);
  } catch {
    artist.added = false;
    put(updateArtist(artist));
    put(updateCurrentUser({ ...currentUser }));
  }
};

export const removeArtist = async (artist: any) => {
  const currentUser = select(getCurrentUser);

  if (!currentUser) return;

  const { id } = artist;
  const artistIndex = currentUser.artists.findIndex(({ id: artistId }) => id === artistId);

  try {
    artist.added = false;
    put(updateArtist(artist));
    put(
      updateCurrentUser(
        cleanObject({
          ...currentUser,
          artists:
            artistIndex !== -1
              ? [...currentUser.artists.slice(0, artistIndex), ...currentUser.artists.slice(artistIndex + 1)]
              : undefined,
        })
      )
    );
    return await removeArtistForUser(currentUser.id, id);
  } catch {
    artist.added = true;
    put(updateArtist(artist));
  }
};

export const toggleArtist = async (artist: any) => {
  const { added } = artist;

  if (Boolean(added)) {
    put(
      addNotification({
        title: 'Artist unfollowed',
        message: `You unfollowed ${get(artist, 'name', 'the artist')}.`,
        items: [artist],
        variant: 'primary',
      })
    );
    removeArtist(artist);
  } else {
    addArtist(artist);
    put(
      addNotification({
        title: 'Artist followed',
        message: `You followed ${get(artist, 'name', 'the artist')}.`,
        items: [artist],
        variant: 'primary',
      })
    );
  }
};

export const getArtist = async (artistId: string, options: { cache: boolean; } = { cache: true }) => {
  const artist = await getArtistApi(artistId, options);
  
  put(updateArtist(artist));

  return artist;
};

/* External dependencies */
import { Action } from 'redux';

/* Internal dependencies */
import { generateActionCreator } from '../helpers/duckGenerators';
import { ApplicationState } from '..';
import { Notification } from 'src/types/Notification';

enum NotificationsActionType {
  ADD_NOTIFICATION = 'reducer/notifications/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'reducer/notifications/REMOVE_NOTIFICATION',
  CLEAR_NOTIFICATIONS = 'reducer/notifications/CLEAR_NOTIFICATIONS',
}

export type NotificationsState = Notification[];

/* Selectors */
export const getNotifications = (state: ApplicationState) => state.notifications;

/* Action creators */
export const addNotification = generateActionCreator<
  NotificationsActionType.ADD_NOTIFICATION,
  Pick<Notification, 'title' | 'message'>
>(NotificationsActionType.ADD_NOTIFICATION);

export const removeNotification = generateActionCreator<NotificationsActionType.REMOVE_NOTIFICATION, string>(
  NotificationsActionType.REMOVE_NOTIFICATION
);

export const clearNotifications = generateActionCreator<NotificationsActionType.CLEAR_NOTIFICATIONS, undefined>(
  NotificationsActionType.CLEAR_NOTIFICATIONS
);

export const INITIAL_STATE: NotificationsState = [];

/* Reducer */
const notificationsReducer = (
  state: NotificationsState = INITIAL_STATE as any,
  action: Action & { payload: any }
): NotificationsState => {
  const { payload } = action;
  if (action.type === NotificationsActionType.ADD_NOTIFICATION) {
    return [
      ...state,
      {
        ...payload,
        id: `${new Date().getTime()}`,
      },
    ];
  }

  if (action.type === NotificationsActionType.REMOVE_NOTIFICATION) {
    const index = state.findIndex(({ id }) => id === payload);
    if (index === -1) return state;

    return [...state.slice(0, index), ...state.slice(index + 1)];
  }

  if (action.type === NotificationsActionType.CLEAR_NOTIFICATIONS) {
    return [];
  }

  return state;
};

export default notificationsReducer;

/* Internal dependencies */
import { PaginationOptions } from '../types/Pagination';
import API from '.';

export const addResultForUser = async (
  userId: string,
  { score, responses, answers }: { score: number; responses: any[]; answers: any[] }
) =>
  await API.post({
    path: `/users-results/${userId}/results`,
    options: {
      body: { score, responses, answers },
    },
  });

export const listResultsForUser = async (
  userId: string,
  { limit, offset }: PaginationOptions
): Promise<any[]> =>
  await API.get({
    path: `/users-results/${userId}/results`,
    options: {
      queryParams: {
        limit,
        offset,
      },
    },
  }) as any;

export const getResultForUser = async (userId: string, resultId: string) =>
  await API.get({
    path: `/users-results/${userId}/results/${resultId}`,
  });

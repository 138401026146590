/* External dependencies */
import React from 'react';

type OwnProps = {
  message: string;
};

type Props = OwnProps;

class ConfirmationModal extends React.Component<Props> {
  event: BeforeUnloadEvent | undefined;
  state = { showModal: false };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
  }

  handleBeforeUnload = (e: BeforeUnloadEvent) => {
    const { message } = this.props;
    e.preventDefault();
    e.stopPropagation();
    e.returnValue = message;
    this.event = e;
  };

  render() {
    return null;
  }
}

export default ConfirmationModal;
